import { BrowserModule } from "@angular/platform-browser";
import { NgModule } from "@angular/core";
import { FormsModule, ReactiveFormsModule } from "@angular/forms";
import {
  TranslateLoader,
  TranslateModule,
  TranslateService,
} from "@ngx-translate/core";
import { TranslateHttpLoader } from "@ngx-translate/http-loader";
import { HttpClientModule, HttpClient } from "@angular/common/http";

// Angular Material Modules
import { BrowserAnimationsModule } from "@angular/platform-browser/animations";
import {
  MatSnackBarModule,
  MAT_SNACK_BAR_DEFAULT_OPTIONS,
} from "@angular/material/snack-bar";
import { MatBadgeModule } from "@angular/material/badge";
import { MatMenuModule } from "@angular/material/menu";
import { MatSidenavModule } from "@angular/material/sidenav";
import { MatTooltipModule } from "@angular/material/tooltip";
import { MatTabsModule } from "@angular/material/tabs";
import {
  MatDialogModule,
  MAT_DIALOG_DEFAULT_OPTIONS,
} from "@angular/material/dialog";
import { MatProgressBarModule } from "@angular/material/progress-bar";
import { MatSlideToggleModule } from "@angular/material/slide-toggle";
import { MatSliderModule } from "@angular/material/slider";
import { MatExpansionModule } from "@angular/material/expansion";
import { MatTableModule } from "@angular/material/table";
import { MatPaginatorModule } from "@angular/material/paginator";
import { MatSortModule } from "@angular/material/sort";

import { AppRoutingModule } from "./app-routing.module";
import { AppComponent } from "./app.component";

import { SharedModule } from "@shared";
import { BuiltinComponent } from "./ui/setting/builtin/builtin.component";
import { AddBuiltinComponent } from "./ui/dialogs/add-builtin/add-builtin.component";
import { StopwordComponent } from "./ui/setting/stopword/stopword.component";
import { VerbalComponent } from "./ui/setting/verbal/verbal.component";
import { SystemComponent } from "./ui/setting/system/system.component";
import { UserComponent } from "./ui/user/user.component";
import { AddUserComponent } from "./ui/dialogs/add-user/add-user.component";
import { AuthComponent } from "./ui/auth/auth.component";
import { ContainerComponent } from "./ui/container/container.component";
import { DashboardComponent } from "./ui/dashboard/dashboard.component";
import { SettingComponent } from "./ui/setting/setting.component";
import { TaskComponent } from "./ui/task/task.component";
import { AuthService } from "./services/auth.service";
import { environment } from "../environments/environment";
import { AccountComponent } from "./ui/setting/account/account.component";
import { ClientTaskComponent } from './ui/task/client-task/client-task.component';
import { BuiltinTaskComponent } from './ui/task/builtin-task/builtin-task.component';

@NgModule({
  declarations: [
    AppComponent,
    AuthComponent,
    ContainerComponent,
    DashboardComponent,
    SettingComponent,
    TaskComponent,
    BuiltinComponent,
    AddBuiltinComponent,
    StopwordComponent,
    VerbalComponent,
    SystemComponent,
    UserComponent,
    AddUserComponent,
    AccountComponent,
    ClientTaskComponent,
    BuiltinTaskComponent,
  ],
  imports: [
    BrowserModule,
    HttpClientModule,
    AppRoutingModule,
    FormsModule,
    ReactiveFormsModule,
    BrowserAnimationsModule,
    MatSnackBarModule,
    MatBadgeModule,
    MatMenuModule,
    MatSidenavModule,
    MatTooltipModule,
    MatTabsModule,
    MatDialogModule,
    MatProgressBarModule,
    MatSlideToggleModule,
    MatSliderModule,
    MatExpansionModule,
    MatTableModule,
    MatPaginatorModule,
    MatSortModule,
    TranslateModule.forRoot({
      loader: {
        provide: TranslateLoader,
        useFactory: HttpLoaderFactory,
        deps: [HttpClient],
      },
    }),
    SharedModule,
  ],
  providers: [
    AuthService,
    { provide: MAT_SNACK_BAR_DEFAULT_OPTIONS, useValue: { duration: 5000 } },
    { provide: MAT_DIALOG_DEFAULT_OPTIONS, useValue: { hasBackdrop: true } },
    TranslateService,
  ],
  // entryComponents: [
  //   ConfirmationDialogComponent,
  //   AddBuiltinComponent
  // ],
  bootstrap: [AppComponent],
})
export class AppModule {}

export function HttpLoaderFactory(http: HttpClient) {
  return environment.mobile
    ? new TranslateHttpLoader(http, "assets/i18n/")
    : new TranslateHttpLoader(http);
}
