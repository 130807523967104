import { Component, OnInit } from '@angular/core';
import { Admin } from '@models/user';
import { Subscription } from 'rxjs';
import { Router } from '@angular/router';
import { AuthService } from '../../services/auth.service';

@Component({
  selector: 'app-setting',
  templateUrl: './setting.component.html',
  styleUrls: ['./setting.component.scss']
})
export class SettingComponent implements OnInit {
  admin: Admin;
  adminSub: Subscription;

  constructor(
    private authService: AuthService,
    private router: Router
  ) { }

  ngOnInit() {
    this.adminSub = this.authService.adminChanges.subscribe(async admin => {
      this.admin = admin;
    });
  }

  isActiveRoute(url: string) {
    return this.router.url.indexOf(url) !== -1;
  }

}
