<div class="dialog">
  <div class="dialog__title" translate>dialog.add-builtin</div>

  <div class="form">
    <div class="form__row id">
      <div class="form__label" translate>general.add</div>
      <div class="form__field">
        <span>friday_</span><input type="text" [(ngModel)]="builtinId">
      </div>

    </div>

    <div class="form__row">
      <div class="form__label" translate>general.default</div>
      <div class="form__field">
        <mat-slide-toggle [(ngModel)]="default">
        </mat-slide-toggle>
      </div>
    </div>


    <!-- <div class="form__section">locales</div> -->
    <div class="form__row" *ngFor="let lang of languages">
      <div class="form__label">{{'general.'+lang | translate}}</div>
      <input class="form__field" type="text" [(ngModel)]="locales[lang]">
    </div>

    <div class="form__row">
      <div class="form__label" translate>general.query</div>
      <div class="form__field">
        <input type="text" [(ngModel)]="sample">
      </div>
    </div>
  </div>

  <div class="dialog__action row">
    <button class="text-button flat" mat-button (click)="close()" translate>general.cancel</button>
    <button class="text-button bg-success" mat-button [mat-dialog-close]="submit()" translate>general.confirm</button>
  </div>
</div>
