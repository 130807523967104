<div
	class="task"
	*ngIf="
		task &&
		task.check > 0 &&
		task.options &&
		task.options.length > 0 &&
		!submitting
	"
>
	<div class="task__title" [class.builtin]="task.builtin">
		<label>{{ task.query }}</label>
		<span *ngIf="!admin" translate>task.select</span>
	</div>

	<div class="task__option">
		<button
			class="task__option__item border-button"
			*ngFor="let option of options"
			(click)="toggleAnswer(option.knowledgeId)"
			[class.checked]="isChecked(option.knowledgeId)"
			[disabled]="readonly || submitting"
		>
			<ng-container *ngIf="!task.builtin">
				<span class="query"> {{ option.knowledgeQuery }} </span>
				<span class="confidence"
					>({{ (option.confidence * 100).toFixed(0) }}%)</span
				>
				<i
					class="material-icons"
					[matTooltip]="option.knowledgeResponse.text"
					[matTooltipPosition]="'above'"
					>info</i
				>
			</ng-container>

			<ng-container *ngIf="task.builtin">
				<span> {{ option.knowledgeId }}</span>
			</ng-container>
		</button>

		<ng-container *ngIf="moreOptions">
			<ng-container *ngIf="showMore">
				<button
					class="task__option__item border-button"
					*ngFor="let option of hiddenOptions"
					(click)="toggleAnswer(option.knowledgeId)"
					[class.checked]="isChecked(option.knowledgeId)"
					[disabled]="readonly || submitting"
				>
					<span class="query"> {{ option.knowledgeQuery }} </span>
					<span class="confidence"
						>({{ (option.confidence * 100).toFixed(0) }}%)</span
					>
					<i
						class="material-icons"
						[matTooltip]="option.knowledgeResponse.text"
						[matTooltipPosition]="'above'"
						>info</i
					>
				</button>
			</ng-container>

			<a class="more" *ngIf="!showMore" (click)="showMore = !showMore" translate
				>general.more-options</a
			>
		</ng-container>

		<button
			class="task__option__item border-button warn"
			(click)="toggleAnswer(UNKNOWN_ANSWER); submitAnswers(true)"
			[class.checked]="isChecked(UNKNOWN_ANSWER)"
			[disabled]="readonly || submitting"
		>
			<span translate>task.cannot-determine</span>
		</button>
	</div>

	<div class="task__action">
		<app-loader-button
			*ngIf="!task.builtin"
			[icon]="'mail'"
			[translation]="'task.reply-only'"
			[disabled]="readonly || answers.length === 0"
			[primary]="true"
			[loading]="submitting"
			(click)="submitAnswers(true)"
		>
		</app-loader-button>

		<app-loader-button
			*ngIf="!task.builtin"
			[icon]="'send'"
			[translation]="'task.reply-and-learn'"
			[disabled]="readonly || answers.length !== 1"
			[black]="true"
			[loading]="submitting"
			(click)="submitAnswers()"
		>
		</app-loader-button>

		<app-loader-button
			*ngIf="task.builtin"
			[icon]="'send'"
			[translation]="'general.submit'"
			[disabled]="readonly || answers.length !== 1"
			[black]="true"
			[loading]="submitting"
			(click)="submitAnswers()"
		>
		</app-loader-button>
	</div>
</div>
