import { Component, OnInit, ViewChild, AfterViewInit } from '@angular/core';
import { UserType } from '@models/user';
import { MatSort } from '@angular/material/sort';
import { MatTableDataSource } from '@angular/material/table';
import { MatPaginator } from '@angular/material/paginator';
import { formatDate } from '@utility';
import { MatDialog } from '@angular/material/dialog';
import { TranslateService } from '@ngx-translate/core';
import { ApiService } from '../../services/api.service';
import { ConfirmationDialogComponent } from '@shared';
import { AddUserComponent } from '../dialogs/add-user/add-user.component';

@Component({
  selector: 'app-user',
  templateUrl: './user.component.html',
  styleUrls: ['./user.component.scss']
})
export class UserComponent implements OnInit {
  @ViewChild(MatSort) sort: MatSort;
  @ViewChild(MatPaginator) paginator: MatPaginator;
  clientColumns = ['email', 'displayName', 'lastSignInTime', 'creationTime', 'emailVerified', 'actions'];
  adminColumns = ['email', 'displayName', 'lastSignInTime', 'creationTime', 'actions'];
  displayedColumns: string[];
  users: any[];
  data: MatTableDataSource<any[]>;
  UserType: typeof UserType = UserType;
  selectedType = UserType.Client;
  searchValue = '';

  constructor(
    private apiService: ApiService,
    public dialog: MatDialog,
    private translateService: TranslateService,
  ) { }

  ngOnInit() {
    this.getUsers();
  }

  async getUsers() {
    const listRes = await this.apiService.listUsers();
    const users = listRes.users || [];
    this.users = users.map(user => ({
      uid: user.uid,
      email: user.email,
      displayName: user.displayName,
      emailVerified: user.emailVerified,
      lastSignInTime: user.metadata.lastSignInTime ? new Date(user.metadata.lastSignInTime) : null,
      creationTime: user.metadata.creationTime ? new Date(user.metadata.creationTime) : null,
      admin: user.customClaims && user.customClaims.admin ? true : false,
      loading: false
    })).sort((a, b) => b.creationTime - a.creationTime);
    this.selectType(this.selectedType);
  }

  selectType(type: UserType) {
    this.selectedType = type;
    switch (type) {
      case UserType.Client:
        this.data = new MatTableDataSource(this.users.filter(u => !u.admin));
        this.displayedColumns = this.clientColumns;
        break;
      case UserType.Admin:
        this.data = new MatTableDataSource(this.users.filter(u => u.admin));
        this.displayedColumns = this.adminColumns;
        break;
    }
    this.data.paginator = this.paginator;
    this.data.sort = this.sort;
  }

  applyFilter() {
    this.data.filter = this.searchValue.trim().toLowerCase();
  }

  formatDate(date: Date): string {
    if (!date) return '-';
    return formatDate(date);
  }

  deleteUser(user: any) {
    console.log(user);
    const ref = this.dialog.open(ConfirmationDialogComponent, {
      data: {
        messageHTML: `<small class="warning"><i class="material-icons">warning</i>${this.translateService.instant('dialog.warning.cannot-undone')}</small> <div>${this.translateService.instant('dialog.warning.confirm-delete')}<span class="highlight">${user.email}</span>? </div> `,
        destructive: true
      }
    });

    ref.afterClosed().subscribe(async confirmed => {
      if (confirmed) {
        user.loading = true;
        switch (this.selectedType) {
          case UserType.Client:
            await this.apiService.deleteClient(user.uid);
            break;
          case UserType.Admin:
            await this.apiService.deleteAdmin(user.uid);
            break;
        }
        this.getUsers();
      }
      user.loading = false;
    });
  }

  createUser() {
    const ref = this.dialog.open(AddUserComponent, { data: { type: this.selectedType } });
    ref.afterClosed().subscribe(confirmed => {
      if (confirmed) this.getUsers();
    });
  }

  // promoteToAdmin(email: string) {
  //   const ref = this.dialog.open(ConfirmationDialogComponent, {
  //     data: {
  //       messageHTML: `<div>${this.translateService.instant('dialog.warning.confirm-add')}<span class="highlight">${email}</span>? </div> `,
  //       destructive: false
  //     }
  //   });

  //   ref.afterClosed().subscribe(async confirmed => {
  //     if (confirmed) {
  //       await this.apiService.createAdmin(email);
  //       this.getUsers();
  //     }
  //   });
  // }

  // removeAdminRole(adminId: string, email: string) {
  //   console.log(adminId, email);
  //   const ref = this.dialog.open(ConfirmationDialogComponent, {
  //     data: {
  //       messageHTML: `<div>${this.translateService.instant('dialog.warning.confirm-delete')}<span class="highlight">${email}</span>? </div> `,
  //       destructive: true
  //     }
  //   });

  //   ref.afterClosed().subscribe(async confirmed => {
  //     if (confirmed) {
  //       await this.apiService.removeAdminRole(adminId);
  //       this.getUsers();
  //     }
  //   });
  // }

}

