import { Language, Timezone } from "../misc";
import { Channel, WhatsappInstanceState } from "../messenger";
import { mask } from "../helper";

interface WitSetting {
	appId: string;
	accessToken: string;
	timezone?: Timezone;
}

export interface FacebookCredential {
	pageName: string;
	pageId: string;
	pageAccessToken: string;
	picture?: string;
}

export interface TwilioCredential {
	accountSid: string;
	authToken: string;
	numbers: string[];
}

export interface WhatsappInfo {
	instanceId: string;
	// serverId: string;
	state?: WhatsappInstanceState;
	qr?: string;
	whatsappId?: string;
	number?: string;
	server?: string;
	device?: {
		wa_version?: string;
		os_version?: string;
		device_manufacturer?: string;
		device_model?: string;
		os_build_number?: string;
	};
	platform?: string;
	pushname?: string;
	profilePic?: string;
}

export enum UserType {
	Admin = "admin",
	Client = "client",
	Taskforce = "taskforce",
}

export enum ClientPermission {
	Free = 0,
	Paid = 1,
	Premium = 2,
	VIP = 3,
}

export enum AdminPermission {
	Operator = 0,
	SuperAdmin = 10,
}

export interface UserInfo {
	firstName?: string;
	lastName?: string;
	displayName?: string;
	photoURL?: string;
	phoneNumber?: string;
	email: string;
}

export interface UserParams {
	id: string;
	info: UserInfo;
	privilege?: {
		type: UserType;
		permission: number;
	};
	_createdAt?: number;
	_updatedAt?: number;
	token?: string;
}

export class User {
	id: string;
	info: UserInfo;
	privilege: {
		type: UserType;
		permission: number;
	};
	_createdAt: number;
	_updatedAt: number;
	token: string;

	constructor(params: UserParams) {
		this.id = params.id;
		this.token = mask(this.id);
		this.info = params.info;
		this._createdAt = params._createdAt || new Date().valueOf();
		this._updatedAt = params._updatedAt || new Date().valueOf();
	}

	toObject() {
		return JSON.parse(JSON.stringify(this));
	}
}

export interface ClientInfo extends UserInfo {
	organization?: string;
}

export class KnowledgeSetting {
	threshold?: number;
	languages?: Language[];
	groups?: any;
	triggers?: {
		knowledgeId: string;
		keywords: string[];
		triggerId: string;
		language: Language;
		comment?: string;
	}[];

	constructor(params: {
		primaryLanguage?: Language;
		threshold?: number;
		languages?: Language[];
		groups?: any;
		triggers?: {
			knowledgeId: string;
			keywords: string[];
			triggerId: string;
			language: Language;
			comment?: string;
		}[];
	}) {
		this.threshold = params.threshold || 0.85;
		this.languages = params.languages
			? params.languages
			: params.primaryLanguage
			? [params.primaryLanguage]
			: [];
		this.groups = params.groups || {};
		this.triggers = params.triggers || [];
	}
	toObject() {
		return JSON.parse(JSON.stringify(this));
	}
}

export class NotificationSettings {
	devices: { name: string; token: string; active: boolean }[];
	constructor(params: {
		devices?: { name: string; token: string; active: boolean }[];
	}) {
		this.devices = params.devices || [];
	}
	toObject() {
		return JSON.parse(JSON.stringify(this));
	}
}

export class ConversationSetting {
	tags: string[];
	channels: Channel[];
	constructor(params: {
		tags?: string[];
		channels?: Channel[];
		archived?: string[]; //depreciated
	}) {
		this.tags = params.tags || [];
		this.channels = params.channels || [];
	}
	toObject() {
		return JSON.parse(JSON.stringify(this));
	}
}

export class TaskforceSetting {
	active: boolean;
	check: number;
	constructor(params: { active?: boolean; check?: number }) {
		this.active = params.active || false;
		this.check = params.check || 2;
	}
	toObject() {
		return JSON.parse(JSON.stringify(this));
	}
}

export class ChatformSetting {
	driveFolderId?: string;
	token?: string;
	constructor(params: { driveFolderId?: string; token?: string }) {
		if (params.driveFolderId) this.driveFolderId = params.driveFolderId;
		if (params.token) this.token = params.token;
	}
	toObject() {
		return JSON.parse(JSON.stringify(this));
	}
}

export interface ClientParams extends UserParams {
	info: ClientInfo;
	primaryLanguage?: Language;
	settings?: {
		wit?: WitSetting;
		knowledge?: KnowledgeSetting;
		taskforce?: TaskforceSetting;
		conversation?: ConversationSetting;
		notification?: NotificationSettings;
		chatform?: ChatformSetting;
	};
	channels?: {
		facebook?: {
			pages: string[];
			credentials: FacebookCredential[];
		};
		whatsapp?: {
			// quota: number;
			instances: string[];
			info: WhatsappInfo[];
		};
		twilio?: TwilioCredential;
	};
	quotas?: {
		whatsapp?: number;
		storage?: number;
	};
}

export class Client extends User {
	info: ClientInfo;
	primaryLanguage?: Language;
	settings: {
		wit: WitSetting;
		knowledge: KnowledgeSetting;
		taskforce: TaskforceSetting;
		conversation: ConversationSetting;
		notification: NotificationSettings;
		chatform: ChatformSetting;
	};
	channels: {
		facebook?: {
			pages: string[];
			credentials: FacebookCredential[];
		};
		whatsapp?: {
			// quota: number;
			instances: string[];
			info: WhatsappInfo[];
		};
		twilio?: TwilioCredential;
	};
	quotas: {
		whatsapp?: number;
		storage?: number;
	};

	constructor(params: ClientParams) {
		super(params);
		this.privilege = params.privilege || {
			type: UserType.Client,
			permission: 0,
		};
		this.info = params.info;
		this.primaryLanguage = params.primaryLanguage || null;
		this.channels = params.channels || {};
		this.settings = {
			wit: params.settings && params.settings.wit ? params.settings.wit : null,
			notification:
				params.settings && params.settings.notification
					? new NotificationSettings(params.settings.notification)
					: new NotificationSettings({}),
			knowledge:
				params.settings && params.settings.knowledge
					? new KnowledgeSetting(params.settings.knowledge)
					: new KnowledgeSetting({ primaryLanguage: this.primaryLanguage }),
			taskforce:
				params.settings && params.settings.taskforce
					? new TaskforceSetting(params.settings.taskforce)
					: new TaskforceSetting({}),
			conversation:
				params.settings && params.settings.conversation
					? new ConversationSetting(params.settings.conversation)
					: new ConversationSetting({}),
			chatform:
				params.settings && params.settings.chatform
					? new ChatformSetting(params.settings.chatform)
					: new ChatformSetting({}),
		};
		this.quotas = params.quotas || {
			whatsapp: 1,
			storage: 100000000,
		};
	}
}

export interface TaskforceParams extends UserParams {
	languages?: Language[];
}

export class Taskforce extends User {
	languages: Language[];

	constructor(params: TaskforceParams) {
		super(params);
		this.privilege = params.privilege || {
			type: UserType.Taskforce,
			permission: 0,
		};
		this.languages = params.languages || [];
	}
}

export interface AdminParams extends UserParams {
	settings?: {
		notification?: NotificationSettings;
	};
}

export class Admin extends User {
	settings?: {
		notification?: NotificationSettings;
	};

	constructor(params: AdminParams) {
		super(params);
		this.privilege = params.privilege || {
			type: UserType.Admin,
			permission: 0,
		};
		this.settings = {
			notification:
				params.settings && params.settings.notification
					? new NotificationSettings(params.settings.notification)
					: new NotificationSettings({}),
		};
	}
}
