<div class="page" *ngIf="admin">
  <div class="page__header ">
    <div class="page__header__title">
      <i class="material-icons">dashboard</i>
      <label translate>nav.dashboard</label>
    </div>
  </div>

  <div class="page__toolbar"></div>

  <div class="page__content">
    <!-- <div class="dashboard" *ngIf="statistics" [@smooth]>
      <div class="dashboard__card">
        <div class="dashboard__card__icon">
          <img src="assets/img/customer-blue.png" alt="customer served">
        </div>
        <div class="dashboard__card__content">
          <label>{{statistics.statistics.customer.served}}</label>
          <span translate>dashboard.statistic.customer-served</span>
        </div>
      </div>

      <div class="dashboard__card">
        <div class="dashboard__card__icon">
          <img src="assets/img/automation-purple.png" alt="automation">
        </div>

        <div class="dashboard__card__content">
          <label>{{statistics.statistics.message.reply_automated.toFixed(2)}}%</label>
          <span translate>dashboard.statistic.automated</span>
        </div>
      </div>

      <div class="dashboard__card">
        <div class="dashboard__card__icon">
          <img src="assets/img/time-green.png" alt="time saved">
        </div>
        <div class="dashboard__card__content">
          <label>{{statistics.statistics.message.time_saved.value.toFixed(2)}}<span>{{statistics.statistics.message.time_saved.unit | translate}}
            </span>
          </label>
          <span translate>dashboard.statistic.saved</span>
        </div>
      </div>

      <div class="dashboard__card">
        <div class="dashboard__card__icon">
          <img src="assets/img/flash-yellow.png" alt="time saved">
        </div>
        <div class="dashboard__card__content">
          <label>{{statistics.statistics.message.averageTime.toFixed(2)}}<span translate>general.second-unit</span>
          </label>
          <span translate>dashboard.statistic.response-time</span>
        </div>
      </div>


      <div class="dashboard__chart large">
        <app-chart [data]="statistics.charts.message_received" [xLabel]="'Date'" [xFormat]="xFormat"
          [type]="ChartType.area" [yAxis]="false" [icon]="'textsms'"
          [title]="'dashboard.chart.messages-replied' | translate"
          [description]="'dashboard.chart.messages-replied-description'| translate"></app-chart>
      </div>

      <div class="dashboard__chart">
        <app-chart [data]="statistics.charts.ai_performance" [type]="ChartType.pie"
          [colors]="[colors.warn, colors.primary]" [icon]="'school'" [title]="'dashboard.chart.performance' | translate"
          [description]="'dashboard.chart.performance-description'| translate">
        </app-chart>
      </div>

      <div class="dashboard__chart">
        <app-chart [data]="statistics.charts.taskforce" [type]="ChartType.pie" [colors]="[colors.black, colors.primary]"
          [icon]="'work'" [title]="'dashboard.chart.taskforce' | translate"
          [description]="'dashboard.chart.taskforce-description'| translate">
        </app-chart>
      </div> 
    </div>-->
  </div>

  <!-- <app-loader *ngIf="!statistics"></app-loader> -->

</div>
