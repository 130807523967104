<button
  class="text-button"
  *ngIf="translation || text"
  [disabled]="disabled || loading"
  [class.bg-success]="success"
  [class.bg-primary]="primary"
  [class.bg-warn]="warn"
  [class.bg-error]="error"
  [class.bg-black]="black"
>
  <i class="material-icons" [class.invisible]="loading" *ngIf="icon">{{
    icon
  }}</i>
  <img *ngIf="img" [src]="img" alt="Button" [class.invisible]="loading" />
  <span translate [class.invisible]="loading" *ngIf="translation">{{
    translation
  }}</span>
  <span [class.invisible]="loading" *ngIf="text">{{ text }}</span>
  <app-loader
    [size]="size || 1.2"
    [darkmode]="darkmode"
    [class.invisible]="!loading"
  ></app-loader>
</button>

<button
  class="icon-button"
  *ngIf="!translation && !text"
  [disabled]="disabled || loading"
  [class.success]="success"
  [class.primary]="primary"
  [class.warn]="warn"
  [class.error]="error"
  [class.black]="black"
>
  <i *ngIf="!loading" class="material-icons">{{ icon }}</i>
  <app-loader
    [size]="size || 1.2"
    [darkmode]="darkmode"
    *ngIf="loading"
  ></app-loader>
</button>
