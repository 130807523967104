const uuid = require("uuid/v1");
import { Language } from "../misc";

export interface QuerySemantic {
	query: string;
	queryEN: string;
	words: string[];
	wordsEN: string[];
	// meanings?: any;
	// meaningsTrans?: any;
}

export interface ParseResult {
	clientId: string;
	knowledgeId?: string;
	origin: string;
	queryLanguage: Language;
	semantic: QuerySemantic;
}

export interface KnowledgeSemanticParams {
	_id?: string;
	clientId: string;
	knowledgeId: string;
	query: string;
	keywords?: string[];
	// keywordsTrans: string[];
	// queries?: string[];
	// meaningKeys: string[],
	// meaningKeysTrans: string[]
	samples: KnowledgeSample[];
}

export class KnowledgeSemantic {
	_id?: string;
	clientId: string;
	knowledgeId: string;
	query: string;
	// queries: string[];
	keywords: string[]; // Recurrent words across multiple samples
	// keywordsTrans: string[]; // Recurrent words across multiple samples
	samples: KnowledgeSample[];

	constructor(params: KnowledgeSemanticParams) {
		this._id = params._id || null;
		this.clientId = params.clientId;
		this.knowledgeId = params.knowledgeId;
		this.query = params.query;
		// this.queries = params.queries || [];
		this.keywords = params.keywords || [];
		// this.keywordsTrans = params.keywordsTrans;
		this.samples = params.samples;
	}
}

export interface KnowledgeSampleParams {
	origin: string;
	_createdAt?: number;
	semantic: QuerySemantic;
}

export class KnowledgeSample {
	sampleId: string;
	origin: string;
	_createdAt: number;
	semantic: QuerySemantic;

	constructor(params: KnowledgeSampleParams) {
		this.sampleId = uuid();
		this.origin = params.origin;
		this._createdAt = params._createdAt || new Date().valueOf();
		this.semantic = params.semantic;
	}
}
