<div class="page__toolbar">
  <button class="text-button" (click)="triggerLoadJSON()">
    <i class="material-icons">cloud_upload</i>
    <span translate>general.import</span>
  </button>
  <input id="load-json" class="hidden" type="file" (change)="loadJSON($event)" accept="application/json">

  <button class="text-button" (click)="export()">
    <i class="material-icons">cloud_download</i>
    <span translate>general.export</span>
  </button>
  <a id="export" class="hidden"></a>
</div>

<div class="page__content">
  <div class="setting" *ngIf="initialized">
    <mat-accordion multi="true" displayMode="flat">
      <mat-expansion-panel *ngFor="let lang of languages">
        <mat-expansion-panel-header>
          <mat-panel-title>{{'general.'+lang | translate}}</mat-panel-title>
        </mat-expansion-panel-header>

        <div class="form">
          <div class="form__row">
            <div class="form__label" translate>general.add</div>
            <div class="form__field">
              <input type="text" [(ngModel)]="newStopword[lang]" (keyup.enter)="addStopword(lang)">
            </div>
            <div class="form__action" *ngIf="newStopword[lang]!==''">
              <button class="icon-button warn" (click)="newStopword[lang]='';">
                <i class="material-icons">cancel</i>
              </button>
              <button class="icon-button success" (click)="addStopword(lang)">
                <i class="material-icons">check_circle</i>
              </button>
            </div>
          </div>

          <div class="samples">
            <div class="samples__item" *ngFor="let word of stopword[lang]; let i = index;">
              {{word}}
              <a class="error" (click)="deleteStopword(lang, word)">
                <i class="material-icons">close</i>
              </a>
            </div>
          </div>
        </div>

      </mat-expansion-panel>

      <ng-container *ngFor="let lang of languages">
        <mat-expansion-panel *ngIf="knowledgeWords[lang] && knowledgeWords[lang].length > 0" [expanded]="true">
          <mat-expansion-panel-header>
            <mat-panel-title>{{'general.word' | translate}} {{'general.'+lang | translate}}</mat-panel-title>
          </mat-expansion-panel-header>
          <div class="samples">
            <div class="samples__item" *ngFor="let word of knowledgeWords[lang]; let i = index;">
              {{word}}
              <a class="success" (click)="addToWhitelist(word)">
                <i class="material-icons">check_circle</i>
              </a>
              <a class="error" (click)="newStopword[lang]=word; addStopword(lang);">
                <i class="material-icons">cancel</i>
              </a>
            </div>
          </div>

        </mat-expansion-panel>
      </ng-container>
    </mat-accordion>
  </div>
  <app-loader *ngIf="!initialized"></app-loader>
</div>