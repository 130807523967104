import { Injectable } from "@angular/core";
import { HttpClient, HttpHeaders } from "@angular/common/http";
import { unique, to } from "@utility";
import { BuiltIn } from "@models/knowledge";
import { Admin } from "@models/user";
import { environment } from "../../environments/environment";
import { NotificationService } from "@shared";
import { AuthService } from "./auth.service";

@Injectable({
	providedIn: "root",
})
export class ApiService {
	private _endpoint = environment.apiEndpoint;
	private _admin: Admin;

	constructor(
		private http: HttpClient,
		private notificationService: NotificationService,
		private authService: AuthService
	) {
		this.authService.adminChanges.subscribe(admin => (this._admin = admin));
	}

	async call(
		url: string,
		body: any,
		m: string,
		admin: boolean = true,
		simpleResponse: boolean = true
	) {
		const [err, res] = await to(
			this.http
				.post(this._endpoint + url, body, {
					headers: new HttpHeaders().set(
						"authorization",
						`Bearer ${
							admin
								? environment.FRIDAY_ADMIN_TOKEN
								: environment.FRIDAY_API_TOKEN
						}`
					),
				})
				.toPromise()
		);
		if (err) return this.notificationService.error(m, false);
		if (!(res as any).success) return this.notificationService.error(m, false);
		return simpleResponse ? true : res;
	}

	async listBuiltins() {
		return this.call(
			"/admin/builtin/list",
			null,
			"error.builtin.list",
			true,
			false
		);
	}

	async listKnowledgeWords() {
		return this.call(
			"/admin/knowledge/word",
			null,
			"error.word.list",
			true,
			false
		);
	}

	async trainBuiltin(knowledgeId: string, samples: string[]) {
		const body = { knowledgeId, samples: unique(samples) };
		return this.call("/admin/builtin/train", body, "error.builtin.add");
	}

	async deleteBuiltinSample(knowledgeId: string, sample: string) {
		const body = { knowledgeId, sample };
		return this.call(
			"/admin/builtin/sample/delete",
			body,
			"error.builtin.delete"
		);
	}

	async addBuiltins(builtins: BuiltIn[], samples: any) {
		const body = { builtins, samples };
		return this.call("/admin/builtin/add", body, "error.builtin.add");
	}

	async cleanApp() {
		const body = { clientId: environment.FRIDAY_ID };
		return this.call("/wit/app/clean", body, "error.builtin.delete", false);
	}

	async deleteBuiltin(knowledgeId: string) {
		const body = { knowledgeId };
		return this.call("/admin/builtin/delete", body, "error.builtin.delete");
	}

	async getWitStatus() {
		return this.call("/admin/wit/info", null, "error.wit.info", true, false);
	}

	async submitTask(taskId: string, answerIds: string[], replyOnly = false) {
		const body = {
			taskId,
			record: {
				answerIds,
				taskforceId: this._admin.id,
				admin: true,
			},
			replyOnly,
		};
		return this.call("/task/submit", body, "error.task.submit", false);
	}

	async listUsers() {
		return this.call("/admin/user/list", null, "error.user.list", true, false);
	}

	async deleteClient(clientId: string) {
		const body = { clientId };
		return this.call("/admin/client/delete", body, "error.user.delete");
	}

	async createClient(
		email: string,
		password: string,
		displayName: string,
		permission: number = 0
	) {
		const body = { email, password, displayName, permission };
		return this.call("/admin/client/create", body, "error.user.delete");
	}

	async createAdmin(
		email: string,
		password?: string,
		displayName?: string,
		permission: number = 0
	) {
		const body = { email, password, displayName, permission };
		return this.call("/admin/create", body, "error.admin.delete");
	}

	async deleteAdmin(adminId: string) {
		const body = { adminId };
		return this.call("/admin/delete", body, "error.admin.delete");
	}

	// async removeAdminRole(adminId: string) {
	//   const body = { adminId };
	//   return this.call('/admin/role/remove', body, 'error.admin.remove');
	// }
}
