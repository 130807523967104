import { NgModule } from "@angular/core";
import { Routes, RouterModule } from "@angular/router";
import { ContainerComponent } from "./ui/container/container.component";
import { DashboardComponent } from "./ui/dashboard/dashboard.component";
import { SettingComponent } from "./ui/setting/setting.component";
import { BuiltinComponent } from "./ui/setting/builtin/builtin.component";
import { SystemComponent } from "./ui/setting/system/system.component";
import { StopwordComponent } from "./ui/setting/stopword/stopword.component";
import { VerbalComponent } from "./ui/setting/verbal/verbal.component";
import { TaskComponent } from "./ui/task/task.component";
import { UserComponent } from "./ui/user/user.component";
import { AuthGuard } from "./guards/auth.guard";
import { AuthComponent } from "./ui/auth/auth.component";
import { NotFoundComponent } from "@shared";
import { AccountComponent } from "./ui/setting/account/account.component";
import { BuiltinTaskComponent } from "./ui/task/builtin-task/builtin-task.component";
import { ClientTaskComponent } from "./ui/task/client-task/client-task.component";

const routes: Routes = [
  {
    path: "",
    component: ContainerComponent,
    children: [
      { path: "dashboard", component: DashboardComponent },
      {
        path: "setting",
        component: SettingComponent,
        children: [
          { path: "builtin", component: BuiltinComponent },
          { path: "system", component: SystemComponent },
          { path: "stopword", component: StopwordComponent },
          { path: "verbal", component: VerbalComponent },
          { path: "account", component: AccountComponent },
          { path: "", redirectTo: "system", pathMatch: "full" },
        ],
      },
      {
        path: "task",
        component: TaskComponent,
        children: [
          { path: "client", component: ClientTaskComponent },
          { path: "builtin", component: BuiltinTaskComponent },
          { path: "", redirectTo: "client", pathMatch: "full" },
        ],
      },
      { path: "user", component: UserComponent },
      { path: "", redirectTo: "task", pathMatch: "full" },
    ],
    canActivate: [AuthGuard],
  },
  { path: "auth", component: AuthComponent },
  { path: "**", component: NotFoundComponent },
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule],
})
export class AppRoutingModule {}
