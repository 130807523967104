const uuid = require("uuid/v1");
import { Attachment } from "./response";

export const FRIDAY_ID = "friday";
export const TWILIO_PREFIX = "whatsapp:+";
export const FRIDAY_SUFFIX = "@";

export enum Channel {
	All = "all",
	Archived = "archived",
	Facebook = "facebook",
	Web = "web",
	Whatsapp = "whatsapp",
}

export const ACTIVE_CHANNELS = [
	Channel.Web,
	Channel.Facebook,
	Channel.Whatsapp,
];

export const FORMATTABLE_CHANNELS = [Channel.Web, Channel.Whatsapp];

export const QUICKREPLY_CHANNELS = [Channel.Web, Channel.Facebook];

export interface CustomerParams {
	customerId?: string;
	name?: string;
	firstName?: string;
	lastName?: string;
	profilePic?: string;
	locale?: string;
	timezone?: string;
	gender?: string;
	email?: string;
	tags?: string[];
	devices?: string[];
	phoneNumber?: string;
	// contacts?: string[];
}
export class Customer {
	customerId: string;
	name?: string;
	firstName?: string;
	lastName?: string;
	profilePic?: string;
	locale?: string;
	timezone?: string;
	gender?: string;
	email?: string;
	tags?: string[];
	devices?: string[];
	phoneNumber?: string;
	// contacts?: string[];

	constructor(params: CustomerParams) {
		this.customerId = params.customerId || uuid();
		if (params.name) this.name = params.name;
		if (params.firstName) this.firstName = params.firstName;
		if (params.lastName) this.lastName = params.lastName;
		if (params.profilePic) this.profilePic = params.profilePic;
		if (params.locale) this.locale = params.locale;
		if (params.timezone) this.locale = params.timezone;
		if (params.gender) this.gender = params.gender;
		if (params.email) this.email = params.email;
		if (params.tags) this.tags = params.tags;
		if (params.phoneNumber) this.phoneNumber = params.phoneNumber;
		if (params.devices) this.devices = params.devices;

		// if (params.contacts) this.contacts = params.contacts;
	}

	toObject() {
		return JSON.parse(JSON.stringify(this));
	}
}

export enum MessageFlags {
	IntentNotFound = "intent_not_found",
	TaskRequired = "task_required",
	Chatform = "chatform",
	MissingResponse = "missing_response",
	BuiltinMissingResponse = "builtin_missing_response",
	FacebookComment = "facebook_comment",

	ErrorCreatingTask = "error_creating_task",
	UnknownError = "unknown_error",
	MissingKnowledge = "missing_knowledge",
	UnknownLanguage = "unknown_language",
	InactiveKnowledge = "inactive_knowledge",
	RespondManually = "respond_manually",
}

export const RESPONSE_FLAGS = [
	MessageFlags.Chatform,
	MessageFlags.FacebookComment,
];

export const isMessageResponse = (message: Message): boolean => {
	return (
		!message.pending &&
		(!message.flag || RESPONSE_FLAGS.includes(message.flag as MessageFlags))
	);
};

export enum QuickReplyType {
	Text = "text",
	Phone = "phone",
	Email = "email",
}

export interface QuickReply {
	type: QuickReplyType;
	title?: string;
	payload?: string;
	image_url?: string;
}

export interface MessageParams {
	senderId: string;
	pending?: boolean;
	messageId?: string;
	timestamp?: number;
	text?: string;
	attachments?: Attachment[];
	context?: string[];
	taskId?: string;
	flag?: MessageFlags | string;
	payload?: any;
	replyTo?: string;
	delivered?: boolean;
	quickReplies?: QuickReply[];
	channel?: Channel;
}

export class Message {
	messageId: string;
	senderId: string;
	timestamp?: number;
	pending?: boolean;
	text?: string;
	attachments?: Attachment[];
	context?: string[];
	taskId?: string;
	flag?: MessageFlags | string;
	payload?: any;
	replyTo?: string;
	delivered: boolean;
	quickReplies?: QuickReply[];
	channel: Channel;

	constructor(params: MessageParams) {
		this.messageId = params.messageId || uuid();
		this.senderId = params.senderId;
		this.timestamp = params.timestamp || new Date().valueOf();
		this.delivered = !!params.delivered;
		this.channel = params.channel || Channel.Web;
		if (params.pending) this.pending = true;
		if (params.text) this.text = params.text;
		if (params.attachments && params.attachments.length > 0)
			this.attachments = params.attachments;
		if (params.context) this.context = params.context;
		if (params.taskId) this.taskId = params.taskId;
		if (params.flag) this.flag = params.flag;
		if (params.payload) this.payload = params.payload;
		if (params.replyTo) this.replyTo = params.replyTo;
		if (params.quickReplies) this.quickReplies = params.quickReplies;
	}

	toObject() {
		return JSON.parse(JSON.stringify(this));
	}
}

export interface ConversationParams {
	clientId: string;
	me: string;
	conversationId?: string;
	customer?: Customer;
	channel?: Channel;
	messages?: Message[];
	_createdAt?: number;
	_updatedAt?: number;
	hidden?: boolean;
	unread?: boolean;
	archived?: boolean;
}

export class Conversation {
	me: string;
	conversationId: string;
	clientId: string;
	customer: Customer;
	channel: Channel;
	messages: Message[];
	_createdAt: number;
	_updatedAt: number;
	hidden: boolean;
	unread: boolean;
	archived: boolean;

	constructor(params: ConversationParams) {
		this.conversationId = params.conversationId || uuid();
		this.me = params.me;
		this.clientId = params.clientId;
		this.customer = params.customer
			? new Customer(params.customer)
			: new Customer({ customerId: this.conversationId });
		this.channel = params.channel || Channel.Web;
		this.messages =
			params.messages && params.messages.length > 0
				? params.messages.map(m =>
						m.channel
							? new Message(m)
							: new Message({ ...m, ...{ channel: this.channel } })
				  )
				: [];
		this._createdAt = params._createdAt || new Date().valueOf();
		this._updatedAt = params._updatedAt || new Date().valueOf();
		this.hidden = !!params.hidden;
		this.unread = !!params.unread;
		this.archived = !!params.archived;
	}

	toObject() {
		return JSON.parse(JSON.stringify(this));
	}
}
