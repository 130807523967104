import { Component, OnInit, OnDestroy } from "@angular/core";
import { AuthService } from "../../../services/auth.service";
import { clone } from "@utility";
import { Admin } from "@models/user";
import { Subscription } from "rxjs";
import { SettingService, NotificationService } from "@shared";
import { DomSanitizer } from "@angular/platform-browser";
import { StorageService } from "../../../services/storage.service";
import { AdminService } from "../../../services/admin.service";
import { animations } from "@shared";

@Component({
  selector: "app-account",
  templateUrl: "./account.component.html",
  styleUrls: ["./account.component.scss"],
  animations: animations,
})
export class AccountComponent implements OnInit, OnDestroy {
  admin: Admin;
  adminSub: Subscription;
  adminCopy: any;
  darkmode: boolean;
  editName = false;
  saving = false;
  password = "";
  confirmPassword = "";
  showEditPhoto = false;

  constructor(
    private authService: AuthService,
    private settingService: SettingService,
    private sanitization: DomSanitizer,
    private storageService: StorageService,
    private notificationService: NotificationService,
    private adminService: AdminService
  ) {}

  ngOnInit() {
    this.adminSub = this.authService.adminChanges.subscribe((admin) => {
      if (admin) {
        this.admin = admin;
        this.adminCopy = clone(admin);
      }
    });

    this.darkmode = this.settingService.settings.darkmode;
  }

  ngOnDestroy() {
    this.reset();
    if (this.adminSub) this.adminSub.unsubscribe();
  }

  reset() {
    this.admin.info.displayName = this.adminCopy.info.displayName || "";
    this.editName = false;
    this.password = "";
    this.confirmPassword = "";
  }

  userPhoto() {
    return this.sanitization.bypassSecurityTrustStyle(
      `url(${this.admin.info.photoURL})`
    );
  }

  async changePassword() {
    if (this.password === "" || this.confirmPassword === "") return false;
    await this.authService.changePassword(this.password, this.confirmPassword);
    this.password = "";
    this.confirmPassword = "";
  }

  async save() {
    this.saving = true;
    let update: any = {};
    if (this.admin.info.displayName)
      update["info.displayName"] = this.admin.info.displayName;
    await this.adminService.updateAdmin(update);
    this.editName = false;
    this.saving = false;
  }

  uploadUserPhoto() {
    document.getElementById("upload-photo").click();
  }

  async onFileChange(event) {
    if (!event.target.files) return false;
    const file = event.target.files[0];
    const url = await this.storageService.uploadUserPhoto(file);
    if (!url) return false;
    await this.adminService.updateAdmin({ "info.photoURL": url });
  }

  toggleDarkmode() {
    this.darkmode = this.settingService.toggleDarkmode();
  }
}
