<div class="page__toolbar"></div>
<div class="page__content">
  <div class="setting" *ngIf="initialized">
    <div class="form">

      <div class="form__section" translate>
        general.basic
      </div>

      <div class="form__row">
        <div class="form__label" translate>system.activate</div>
        <div class="form__field">
          <mat-slide-toggle [checked]="system.active" (change)="toggleActive($event)">
          </mat-slide-toggle>
        </div>
      </div>

      <div class="form__row">
        <div class="form__label" translate>config.threshold</div>
        <div class="form__field">
          <mat-slider min="0.5" max="0.95" step="0.05" thumbLabel [(ngModel)]="system.threshold">
          </mat-slider>
          <label>{{system.threshold}}</label>
        </div>
        <div class="form__action" *ngIf="system.threshold !== systemCopy.threshold">
          <button class="icon-button warn" (click)="reset()">
            <i class="material-icons">cancel</i>
          </button>
          <button class="icon-button success" (click)="save()">
            <i class="material-icons">check_circle</i>
          </button>
        </div>
      </div>
    </div>
  </div>
  <app-loader *ngIf="!initialized"></app-loader>
</div>
