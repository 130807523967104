import { SupportedLanguages } from "../misc";

export interface BuiltinConfig {
  [key: string]: BuiltIn;
}

export interface BuiltinParams {
  id: string;
  locales: any;
  order?: number;
  default?: boolean;
  query?: string;
}

export class BuiltIn {
  id: string;
  locales: any;
  order?: number;
  default: boolean;
  query?: string;

  constructor(params: BuiltinParams) {
    this.id = params.id;
    if (params.locales) {
      this.locales = params.locales;
    } else {
      this.locales = {};
      SupportedLanguages.forEach((language) => (this.locales[language] = ""));
    }
    if (params.order) this.order = params.order;
    this.query = params.query || "";
    this.default = params.default || false;
  }

  toObject() {
    return JSON.parse(JSON.stringify(this));
  }
}

export const IgnorableBuiltins = [
  "friday_start_conversation",
  "friday_end_conversation",
  "friday_thank",
];

// export interface BuiltinRequestParams {
//   id: string;
//   knowledgeId: string;
//   query: string;
//   pending?: boolean;
//   _createdAt?: number;
//   _updatedAt?: number;
// }
// export class BuiltinRequest {
//   id: string;
//   knowledgeId: string;
//   query: string;
//   pending: boolean;
//   _createdAt: number;
//   _updatedAt: number;

//   constructor(params: BuiltinRequestParams) {
//     this.id = params.id;
//     this.query = params.query;
//     this.knowledgeId = params.knowledgeId;
//     this.pending = params.pending || true;
//     this._createdAt = params._createdAt || new Date().valueOf();
//     this._updatedAt = params._updatedAt || new Date().valueOf();
//   }

//   toObject() {
//     return JSON.parse(JSON.stringify(this));
//   }
// }
