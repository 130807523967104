import { NgModule } from "@angular/core";
import { FormsModule, ReactiveFormsModule } from "@angular/forms";
import { LoaderComponent } from "./components/loader/loader.component";
import { CommonModule } from "@angular/common";
import { ChartComponent } from "./components/chart/chart.component";
import { NgxChartsModule } from "@swimlane/ngx-charts";
import { PrivacyComponent } from "./components/privacy/privacy.component";
import { CookieService } from "ngx-cookie-service";
import { FormComponent } from "./components/form/form.component";
import { TranslateModule } from "@ngx-translate/core";
import { SidebarComponent } from "./components/sidebar/sidebar.component";
import { MatMenuModule } from "@angular/material/menu";
import { RouterModule } from "@angular/router";
import { TopComponent } from "./components/navbar/top/top.component";
import { BottomComponent } from "./components/navbar/bottom/bottom.component";
import { NotFoundComponent } from "./components/not-found/not-found.component";
import { ConfirmationDialogComponent } from "./components/dialogs/confirmation/confirmation.component";
import { MatDialogModule } from "@angular/material/dialog";
import { LoaderButtonComponent } from "./components/loader-button/loader-button.component";
import { StatusComponent } from "./components/status/status.component";
import { MatTooltipModule } from "@angular/material/tooltip";
import { EmojiButtonComponent } from "./components/emoji/emoji.component";
import { PickerModule } from "@ctrl/ngx-emoji-mart";
import { EmptyComponent } from "./components/empty/empty.component";
import { TaskCardComponent } from "./components/task-card/task-card.component";

@NgModule({
  declarations: [
    LoaderComponent,
    ChartComponent,
    PrivacyComponent,
    FormComponent,
    SidebarComponent,
    TopComponent,
    BottomComponent,
    NotFoundComponent,
    ConfirmationDialogComponent,
    LoaderButtonComponent,
    StatusComponent,
    EmojiButtonComponent,
    EmptyComponent,
    TaskCardComponent,
  ],
  imports: [
    CommonModule,
    NgxChartsModule,
    FormsModule,
    ReactiveFormsModule,
    MatMenuModule,
    RouterModule,
    MatDialogModule,
    TranslateModule,
    MatTooltipModule,
    PickerModule,
  ],
  providers: [CookieService],
  exports: [
    NgxChartsModule,
    LoaderComponent,
    ChartComponent,
    PrivacyComponent,
    FormComponent,
    SidebarComponent,
    TopComponent,
    BottomComponent,
    NotFoundComponent,
    ConfirmationDialogComponent,
    LoaderButtonComponent,
    StatusComponent,
    EmojiButtonComponent,
    EmptyComponent,
    TaskCardComponent,
  ],
})
export class SharedModule {}
