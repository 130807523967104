import { Injectable } from "@angular/core";
import * as firebase from "firebase/app";
import "firebase/firestore";
import { NotificationService } from "@shared";
import { BehaviorSubject } from "rxjs";
import { to, unique, stringToDate } from "@utility";
import { BuiltIn, BuiltinParams } from "@models/knowledge";
import { ApiService } from "./api.service";
import { environment } from "../../environments/environment";
import { AuthService } from "./auth.service";

@Injectable({
  providedIn: "root",
})
export class BuiltinService {
  private _collection = firebase
    .firestore()
    .collection(environment.BUILTIN_COLLECTION);
  private _builtin$: BehaviorSubject<BuiltIn[]> = new BehaviorSubject(null);
  public builtinChanges = this._builtin$.asObservable();
  builtins: BuiltIn[];
  builtinUnsub: any;
  builtinIds: string[];

  constructor(
    private authService: AuthService,
    private notificationService: NotificationService,
    private apiService: ApiService
  ) {
    this.authService.authUserChanges.subscribe((authUser) => {
      if (!authUser) {
        if (this.builtinUnsub) this.builtinUnsub();
      }
    });
  }

  async listenBuiltins() {
    if (!this.builtins) {
      this.builtinUnsub = this._collection.onSnapshot(
        async (snapshot) => {
          this.builtins = [];
          if (!snapshot.empty) {
            snapshot.forEach((doc) => {
              this.builtins.push(new BuiltIn(doc.data() as BuiltinParams));
            });
          } else {
            await this.apiService.cleanApp();
          }
          this.builtins = this.builtins.sort((a, b) => a.order - b.order);
          // console.log(this.builtins);
          this.builtinIds = this.builtins.map((b) => b.id);
          this._builtin$.next(this.builtins);
        },
        (err) => {
          console.error(err);
          this.notificationService.error("error.builtin.missing");
        }
      );
    }
  }

  async getSamples() {
    const builtinSamples = {};
    const sampleRes = await this.apiService.listBuiltins();
    // console.log(sampleRes);
    if (sampleRes) {
      this.builtinIds.forEach((id) => {
        builtinSamples[id] = { kb: [], wit: [] };
        if (sampleRes.kb[id])
          builtinSamples[id].kb = unique(sampleRes.kb[id]).sort();
        if (sampleRes.wit[id])
          builtinSamples[id].wit = unique(sampleRes.wit[id]).sort();
      });
    }
    // console.log(builtinSamples);
    return builtinSamples;
  }

  async getWitStatus() {
    const witInfo = await this.apiService.getWitStatus();
    console.log(witInfo);
    if (witInfo && witInfo.training_status !== "done") {
      if (witInfo.training_status === "ongoing") {
        setTimeout((_) => this.listenBuiltins(), 60000);
      } else {
        const t =
          stringToDate(witInfo.will_train_at).valueOf() -
          new Date().valueOf() +
          parseInt(witInfo.last_training_duration_secs) * 1000;
        setTimeout((_) => this.listenBuiltins(), t);
      }
    }
    return witInfo;
  }

  async update(id: string, update: any) {
    const [updateErr, updateRes] = await to(
      this._collection.doc(id).update(update)
    );
    if (updateErr)
      return this.notificationService.error("error.data.update", false);
    return true;
  }
}
