import { Component, OnInit } from "@angular/core";
import { TranslateService } from "@ngx-translate/core";
import { MatDialog } from "@angular/material/dialog";
import { SupportedLanguages, Language } from "@models/misc";
import { unique } from "@utility";
import { NlpService } from "../../../services/nlp.service";
import { NotificationService } from "@shared";
import { StorageService } from "../../../services/storage.service";
import { ConfirmationDialogComponent } from "@shared";

@Component({
  selector: "app-stopword",
  templateUrl: "./stopword.component.html",
  styleUrls: ["./stopword.component.scss"],
})
export class StopwordComponent implements OnInit {
  initialized = false;
  stopword: any;
  languages = SupportedLanguages;
  newStopword = {};
  knowledgeWords: any;
  whitelist: string[];

  constructor(
    private translateService: TranslateService,
    private nlpService: NlpService,
    public dialog: MatDialog,
    private notificationService: NotificationService,
    private storageService: StorageService
  ) {}

  ngOnInit() {
    this.languages.forEach((lang) => (this.newStopword[lang] = ""));
    this.nlpService.listenStopword();
    this.nlpService.stopwordChanges.subscribe((res) => {
      if (res) {
        console.log(res);
        this.stopword = res.stopword;
        this.knowledgeWords = res.knowledgeWords;
        this.whitelist = res.whitelist;
        // console.log(this.stopword);
        this.initialized = true;
      }
    });
  }

  triggerLoadJSON() {
    const element = document.getElementById("load-json") as HTMLInputElement;
    element.value = null;
    element.click();
  }

  async loadJSON(event) {
    const json = await this.storageService.loadJSON(event);
    if (!json) return false;
    console.log(json);
    for (const language of this.languages) {
      if (json[language]) {
        this.stopword[language] = unique([
          ...this.stopword[language],
          ...json[language],
        ]);
      }
    }

    this.whitelist = unique([...this.whitelist, ...json.whitelist]);
    // console.log(this.stopword);
    await this.nlpService.setStopword(this.stopword);
    await this.nlpService.setStopwordWhitelist(this.whitelist);
    return true;
  }

  export() {
    const download = document.getElementById("export");
    const json = { ...this.stopword, ...{ whitelist: this.whitelist } };
    download.setAttribute(
      "href",
      "data:text/plain;charset=utf-8," +
        encodeURIComponent(JSON.stringify(json))
    );
    const t = new Date().toISOString().substr(0, 16).replace(/[-:]/g, "");
    download.setAttribute("download", `friday_stopword_${t}.json`);
    download.click();
  }

  addStopword(lang: Language) {
    if (this.newStopword[lang] === "") return false;
    if (this.stopword[lang].indexOf(this.newStopword[lang]) !== -1) {
      this.newStopword[lang] = "";
      return this.notificationService.error("error.data.existing", false);
    }

    const dialogRef = this.dialog.open(ConfirmationDialogComponent, {
      data: {
        messageHTML: `<div>${this.translateService.instant(
          "dialog.warning.confirm-add"
        )}<span class="highlight">${this.newStopword[lang]}</span>? </div> `,
      },
    });

    dialogRef.afterClosed().subscribe(async (confirmed) => {
      if (confirmed) {
        const res = await this.nlpService.addStopword(
          lang,
          this.newStopword[lang]
        );
        this.newStopword[lang] = "";
        return true;
      }
      return false;
    });
  }

  deleteStopword(lang: Language, word: string) {
    const dialogRef = this.dialog.open(ConfirmationDialogComponent, {
      data: {
        messageHTML: `<small class="warning"><i class="material-icons">warning</i>${this.translateService.instant(
          "dialog.warning.cannot-undone"
        )}</small> <div>${this.translateService.instant(
          "dialog.warning.confirm-delete"
        )}<span class="highlight">${word}</span>? </div> `,
        destructive: true,
      },
    });

    dialogRef.afterClosed().subscribe(async (confirmed) => {
      if (confirmed) {
        const res = await this.nlpService.deleteStopword(lang, word);
        return true;
      }
      return false;
    });
  }

  addToWhitelist(word: string) {
    return this.nlpService.addStopwordWhitelist(word);
  }
}
