const uuid = require("uuid/v1");
import { Response, Channel } from "../messenger";
import { Language } from "../misc";

export const UNKNOWN_ANSWER = "none";

export interface TaskRequest {
	taskId?: string;
	clientId: string;
	channel: Channel;
	query: string;
	queryLanguage: Language;
	possibleOptions: any[];
	check?: number;
	conversationId?: string;
	me?: string;
	messageId?: string;
	public: boolean;
	primaryLanguage?: Language;
}

export interface TaskOption {
	knowledgeId: string;
	query: string;
	knowledgeQuery?: string;
	queryLanguage?: Language;
	knowledgeResponse?: Response;
	confidence?: number;
}

export interface TaskRecordParams {
	answerIds: string[];
	taskforceId: string;
	recordId?: string;
	timestamp?: number;
	admin?: boolean;
	taskforce?: boolean;
}

export class TaskRecord {
	answerIds: string[];
	taskforceId: string;
	recordId: string;
	timestamp: number;
	admin: boolean;
	taskforce: boolean;

	constructor(params: TaskRecordParams) {
		this.answerIds = params.answerIds;
		this.taskforceId = params.taskforceId;
		this.recordId = params.recordId || uuid();
		this.timestamp = params.timestamp || new Date().valueOf();
		this.admin = !!params.admin;
		this.taskforce = !!params.taskforce;
	}

	toObject() {
		return JSON.parse(JSON.stringify(this));
	}
}

export interface TaskParams {
	taskId: string;
	clientId: string;
	conversationId?: string;
	me?: string;
	channel?: Channel;
	messageId?: string;
	query: string;
	queryLanguage: Language;
	check: number;
	options: TaskOption[];
	records?: TaskRecord[];
	resultIds?: string[];
	public?: boolean;
	_createdAt?: number;
	_updatedAt?: number;
	builtin?: boolean;
	completed?: boolean;
}

export class Task {
	taskId: string;
	clientId: string;
	conversationId?: string;
	me?: string;
	channel?: Channel;
	messageId?: string;
	query: string;
	queryLanguage: Language;
	check: number;
	options: TaskOption[];
	records: TaskRecord[];
	resultIds?: string[];
	public: boolean;
	_createdAt: number;
	_updatedAt: number;
	builtin: boolean;
	completed: boolean;

	constructor(params: TaskParams) {
		this.taskId = params.taskId;
		this.clientId = params.clientId;
		if (params.channel) this.channel = params.channel;
		if (params.conversationId) this.conversationId = params.conversationId;
		if (params.me) this.me = params.me;
		if (params.messageId) this.messageId = params.messageId;
		this.query = params.query;
		this.queryLanguage = params.queryLanguage;
		this.check = params.check === 0 ? 0 : params.check || 2;
		this.options = params.options || [];
		this.records = params.records || [];
		this.resultIds = params.resultIds || [];
		this.public = !!params.public;
		this._createdAt = params._createdAt || new Date().valueOf();
		this._updatedAt = params._updatedAt || new Date().valueOf();
		this.builtin = !!params.builtin;
		this.completed = this.check <= 0 ? true : !!params.completed;
	}

	toObject() {
		return JSON.parse(JSON.stringify(this));
	}
}
