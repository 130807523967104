<div class="page">
  <div class="page__header">
    <div class="page__header__title">
      <i class="material-icons">people</i>
      <label translate>nav.user</label>
    </div>

    <div class="page__header__tab">
      <a [class.selected]="selectedType == UserType.Client" (click)="selectType(UserType.Client)">
        <div class="menu-title" translate>nav.client</div>
      </a>

      <a [class.selected]="selectedType == UserType.Admin" (click)="selectType(UserType.Admin)">
        <div class="menu-title" translate>nav.admin</div>
      </a>
    </div>

  </div>

  <div class="page__toolbar">
    <div class="left row">
      <button class="text-button" (click)="createUser()">
        <i class="material-icons">person_add</i>
        <span translate *ngIf="selectedType == UserType.Client">nav.client</span>
        <span translate *ngIf="selectedType == UserType.Admin">nav.admin</span>
      </button>

      <div class="search">
        <i class="material-icons">search</i>
        <input type="text" placeholder="search" [(ngModel)]="searchValue" (ngModelChange)="applyFilter()">
        <a *ngIf="searchValue!==''" (click)="searchValue = '';applyFilter();"><i
            class="material-icons cancel">cancel</i></a>
      </div>
    </div>

    <div class="right row">
      <button class="text-button" (click)="getUsers()">
        <i class="material-icons">autorenew</i>
      </button>
    </div>
  </div>


  <div class="page__content">
    <app-loader *ngIf="!users"></app-loader>
    <div class="setting" [hidden]="!users">
      <table mat-table [dataSource]="data" matSort>
        <!-- <ng-container matColumnDef="uid">
          <th mat-header-cell *matHeaderCellDef mat-sort-header> UID </th>
          <td mat-cell *matCellDef="let element"> {{element.uid}} </td>
        </ng-container> -->

        <ng-container matColumnDef="email">
          <th mat-header-cell *matHeaderCellDef mat-sort-header><span translate>user.email</span></th>
          <td mat-cell *matCellDef="let element">
            <span [matTooltip]="element.uid" [matTooltipPosition]="'right'">{{element.email}}</span>
          </td>
        </ng-container>

        <ng-container matColumnDef="displayName">
          <th mat-header-cell *matHeaderCellDef mat-sort-header><span translate>user.displayName</span></th>
          <td mat-cell *matCellDef="let element"> {{element.displayName}} </td>
        </ng-container>

        <ng-container matColumnDef="lastSignInTime">
          <th mat-header-cell *matHeaderCellDef mat-sort-header><span translate>user.lastSignInTime</span></th>
          <td mat-cell *matCellDef="let element"> {{formatDate(element.lastSignInTime)}} </td>
        </ng-container>

        <ng-container matColumnDef="creationTime">
          <th mat-header-cell *matHeaderCellDef mat-sort-header><span translate>user.creationTime</span></th>
          <td mat-cell *matCellDef="let element"> {{formatDate(element.creationTime)}} </td>
        </ng-container>

        <ng-container matColumnDef="emailVerified">
          <th mat-header-cell *matHeaderCellDef mat-sort-header><span translate>general.status</span></th>
          <td mat-cell *matCellDef="let element">
            <i class="material-icons success" *ngIf="element.emailVerified && !element.loading"
              [matTooltip]="'user.emailVerified' | translate" [matTooltipPosition]="'right'">check_circle_outline</i>
            <i class="material-icons warn" *ngIf="!element.emailVerified && !element.loading"
              [matTooltip]="'user.not-verified' | translate" [matTooltipPosition]="'right'">warning</i>
            <i class="material-icons" *ngIf="element.loading" [matTooltip]="'general.in-progress' | translate"
              [matTooltipPosition]="'right'">hourglass_empty</i>
          </td>
        </ng-container>

        <ng-container matColumnDef="actions">
          <th mat-header-cell *matHeaderCellDef></th>
          <td mat-cell *matCellDef="let element">
            <button class="icon-button" [matMenuTriggerFor]="userMenu" [matMenuTriggerData]="{user: element}"
              *ngIf="element.email!=='admin@automagic.xyz'" [disabled]="element.loading">
              <i class="material-icons">more_vert</i>
            </button>
          </td>
        </ng-container>

        <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
        <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>
      </table>
      <mat-paginator *ngIf="data" [pageSize]="10" [length]="data.data.length" showFirstLastButtons>
      </mat-paginator>
    </div>



  </div>


</div>

<mat-menu #userMenu="matMenu" xPosition="after" yPosition="below">
  <ng-template matMenuContent let-user="user">


    <button mat-menu-item class="text-button error" (click)="deleteUser(user)">
      <i class="material-icons ">clear</i>
      <span translate>general.delete</span>
    </button>

    <!-- <button mat-menu-item class="text-button warn" (click)="removeAdminRole(id,email)"
      *ngIf="selectedType == UserType.Admin">
      <i class="material-icons ">verified_user</i>
      <span translate>user.remove-admin</span>
    </button> -->

    <!-- <button mat-menu-item class="text-button warn" (click)="promoteToAdmin(email)"
      *ngIf="selectedType !== UserType.Admin">
      <i class="material-icons ">verified_user</i>
      <span translate>user.set-as-admin</span>
    </button> -->
  </ng-template>


</mat-menu>
