<div class="dialog">
  <div class="dialog__title" translate *ngIf="type==UserType.Client">dialog.add-client</div>
  <div class="dialog__title" translate *ngIf="type==UserType.Admin">dialog.add-admin</div>

  <div class="form">
    <div class="form__row">
      <div class="form__label" translate>auth.email</div>
      <div class="form__field">
        <input type="email" [(ngModel)]="email" autocomplete="off">
      </div>
    </div>

    <div class="form__row">
      <div class="form__label" translate>auth.password</div>
      <div class="form__field">
        <input type="password" [(ngModel)]="password" autocomplete="off">
      </div>
    </div>

    <div class="form__row">
      <div class="form__label" translate>auth.confirm-password</div>
      <div class="form__field">
        <input type="password" [(ngModel)]="confirmPassword" autocomplete="off">
      </div>
    </div>

    <div class="form__row">
      <div class="form__label" translate>user.displayName</div>
      <div class="form__field">
        <input type="text" [(ngModel)]="displayName" autocomplete="off"
          [placeholder]="'placeholder.optional' | translate">
      </div>
    </div>

  </div>

  <div class="dialog__action row">
    <button class="text-button flat" mat-button (click)="close()" translate>general.cancel</button>
    <button class="text-button bg-success" [disabled]="!valid() || loading" mat-button (click)="createUser()"
      translate>general.confirm</button>
  </div>
</div>
