import { Component, OnInit } from "@angular/core";
import { TranslateService } from "@ngx-translate/core";
import { MatDialog } from "@angular/material/dialog";
import { unique } from "@utility";
import { SupportedLanguages } from "@models/misc";
import { NlpService } from "../../../services/nlp.service";
import { ApiService } from "../../../services/api.service";
import { NotificationService, ConfirmationDialogComponent } from "@shared";
import { StorageService } from "../../../services/storage.service";

@Component({
  selector: "app-verbal",
  templateUrl: "./verbal.component.html",
  styleUrls: ["./verbal.component.scss"],
})
export class VerbalComponent implements OnInit {
  initialized = false;
  verbal: any;
  languages = SupportedLanguages;
  newVerbalWord = "";
  newOrigin = "";
  newTranslation = "";
  translationKeys = [];

  constructor(
    private translateService: TranslateService,
    private nlpService: NlpService,
    private apiService: ApiService,
    public dialog: MatDialog,
    private notificationService: NotificationService,
    private storageService: StorageService
  ) {}

  ngOnInit() {
    this.nlpService.listenVerbal();
    this.nlpService.verbalChanges.subscribe((verbal) => {
      if (verbal) {
        this.verbal = verbal;
        console.log(this.verbal);
        this.translationKeys = Object.keys(this.verbal.translation)
          .sort()
          .sort((a, b) => a.length - b.length);
        this.initialized = true;
      }
    });
  }

  triggerLoadJSON() {
    const element = document.getElementById("load-json") as HTMLInputElement;
    element.value = null;
    element.click();
  }

  async loadJSON(event) {
    const json = await this.storageService.loadJSON(event);
    if (!json) return false;
    // console.log(json);
    if (json.word)
      this.verbal.word = unique([...this.verbal.word, ...json.word]);
    if (json.translation)
      this.verbal.translation = {
        ...this.verbal.translation,
        ...json.translation,
      };
    // console.log(this.verbal);
    await this.nlpService.setVerbal(this.verbal);
    return true;
  }

  export() {
    const download = document.getElementById("export");
    download.setAttribute(
      "href",
      "data:text/plain;charset=utf-8," +
        encodeURIComponent(JSON.stringify(this.verbal))
    );
    const t = new Date().toISOString().substr(0, 16).replace(/[-:]/g, "");
    download.setAttribute("download", `friday_verbal_${t}.json`);
    download.click();
  }

  addVerbalWord() {
    if (this.newVerbalWord === "") return false;
    if (this.verbal.word.indexOf(this.newVerbalWord) !== -1) {
      this.newVerbalWord = "";
      return this.notificationService.error("error.data.existing", false);
    }

    const dialogRef = this.dialog.open(ConfirmationDialogComponent, {
      data: {
        messageHTML: `<div>${this.translateService.instant(
          "dialog.warning.confirm-add"
        )}<span class="highlight">${this.newVerbalWord}</span>? </div> `,
      },
    });

    dialogRef.afterClosed().subscribe(async (confirmed) => {
      if (confirmed) {
        const res = await this.nlpService.addVerbalWord(this.newVerbalWord);
        this.newVerbalWord = "";
        return true;
      }
      return false;
    });
  }

  deleteVerbalWord(word: string) {
    const dialogRef = this.dialog.open(ConfirmationDialogComponent, {
      data: {
        messageHTML: `<small class="warning"><i class="material-icons">warning</i>${this.translateService.instant(
          "dialog.warning.cannot-undone"
        )}</small> <div>${this.translateService.instant(
          "dialog.warning.confirm-delete"
        )}<span class="highlight">${word}</span>? </div> `,
        destructive: true,
      },
    });

    dialogRef.afterClosed().subscribe(async (confirmed) => {
      if (confirmed) {
        const res = await this.nlpService.deleteVerbalWord(word);
        return true;
      }
      return false;
    });
  }

  addVerbalTranslation() {
    if (this.newOrigin === "" || this.newTranslation === "") return false;
    if (Object.keys(this.verbal.translation).indexOf(this.newOrigin) !== -1) {
      this.newOrigin = "";
      this.newTranslation = "";
      return this.notificationService.error("error.data.existing", false);
    }

    const dialogRef = this.dialog.open(ConfirmationDialogComponent, {
      data: {
        messageHTML: `<div>${this.translateService.instant(
          "dialog.warning.confirm-add"
        )}<span class="highlight">${this.newOrigin} => ${
          this.newTranslation
        }</span>? </div> `,
      },
    });

    dialogRef.afterClosed().subscribe(async (confirmed) => {
      if (confirmed) {
        const res = await this.nlpService.addVerbalTranslation(
          this.newOrigin,
          this.newTranslation
        );
        this.newOrigin = "";
        this.newTranslation = "";
        return true;
      }
      return false;
    });
  }

  deleteVerbalTranslation(origin: string) {
    const dialogRef = this.dialog.open(ConfirmationDialogComponent, {
      data: {
        messageHTML: `<small class="warning"><i class="material-icons">warning</i>${this.translateService.instant(
          "dialog.warning.cannot-undone"
        )}</small> <div>${this.translateService.instant(
          "dialog.warning.confirm-delete"
        )}<span class="highlight">${origin}</span>? </div> `,
        destructive: true,
      },
    });

    dialogRef.afterClosed().subscribe(async (confirmed) => {
      if (confirmed) {
        const res = await this.nlpService.deleteVerbalTranslation(origin);
        return true;
      }
      return false;
    });
  }
}
