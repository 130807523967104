import { Component, OnInit } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { MatDialog } from '@angular/material/dialog';
import { NlpService } from '../../../services/nlp.service';
import { ConfirmationDialogComponent } from '@shared';


@Component({
  selector: 'app-system',
  templateUrl: './system.component.html',
  styleUrls: ['./system.component.scss']
})
export class SystemComponent implements OnInit {
  system: any;
  initialized = false;
  systemCopy: any;

  constructor(
    private translateService: TranslateService,
    private nlpService: NlpService,
    public dialog: MatDialog,
  ) { }

  ngOnInit() {
    this.nlpService.listenSystem();
    this.nlpService.systemChanges.subscribe(system => {
      if (system) {
        this.system = system;
        this.systemCopy = JSON.parse(JSON.stringify(system));
        console.log(this.system);
        this.initialized = true;
      }
    });
  }

  reset() {
    this.system = JSON.parse(JSON.stringify(this.systemCopy));
  }

  async save() {
    await this.nlpService.updateSystem({
      threshold: this.system.threshold
    });
  }

  async toggleActive(event) {
    // console.log(event);
    const source = event.source;
    const active = event.checked;
    source.toggle();

    if (!active) {
      const dialogRef = this.dialog.open(ConfirmationDialogComponent, {
        data: {
          messageHTML: `<small class="warning"><i class="material-icons">warning</i>${this.translateService.instant('dialog.warning.caution')}</small> <div>${this.translateService.instant('dialog.warning.confirm-deactivate-friday')}</div> `,
          destructive: true
        }
      });


      dialogRef.afterClosed().subscribe(async confirmed => {
        this.system.active = confirmed ? active : !active;
        if (confirmed) {
          source.toggle();
          await this.nlpService.updateSystem({ active: this.system.active });
        }
      });
    } else {
      source.toggle();
      await this.nlpService.updateSystem({ active: true });
    }
  }


}
