import { Injectable } from "@angular/core";
import * as firebase from "firebase/app";
import "firebase/firestore";
import { BehaviorSubject } from "rxjs";
import { Task, TaskParams } from "@models/task";
import { environment } from "../../environments/environment";
import { NotificationService } from "@shared";
import { AuthService } from "./auth.service";
import { ApiService } from "./api.service";

@Injectable({
	providedIn: "root",
})
export class TaskService {
	private _collection = firebase
		.firestore()
		.collection(environment.TASK_COLLECTION);
	private _clientTask$: BehaviorSubject<Task[]> = new BehaviorSubject(null);
	public clientTaskChanges = this._clientTask$.asObservable();
	private _builtinTask$: BehaviorSubject<Task[]> = new BehaviorSubject(null);
	public builtinTaskChanges = this._builtinTask$.asObservable();

	taskUnsub: any;

	constructor(
		private authService: AuthService,
		private notificationService: NotificationService,
		private apiService: ApiService
	) {
		this.authService.authUserChanges.subscribe(authUser => {
			if (!authUser && this.taskUnsub) this.taskUnsub();
			if (authUser) this.listenTask();
		});
	}

	listenTask() {
		if (!this.taskUnsub)
			this.taskUnsub = this._collection.where("check", ">", 0).onSnapshot(
				docs => {
					let tasks: Task[] = [];
					if (!docs.empty) {
						docs.forEach(doc => {
							const task = new Task(doc.data() as TaskParams);
							if (task.public || task.builtin) tasks.push(task);
						});
					}
					tasks = tasks.sort((a, b) => a._createdAt - b._createdAt);
					this._clientTask$.next(tasks.filter(t => !t.builtin));
					this._builtinTask$.next(tasks.filter(t => t.builtin));
				},
				error => {
					console.log(error);
					this.notificationService.error("error.data.get");
				}
			);
	}

	submitTask(taskId: string, answers: string[], replyOnly = false) {
		return this.apiService.submitTask(taskId, answers, replyOnly);
	}
}
