import { Component, OnInit, Input } from '@angular/core';
import { Router } from '@angular/router';


@Component({
  selector: 'app-nav-bottom',
  templateUrl: './bottom.component.html',
  styleUrls: ['./bottom.component.scss']
})
export class BottomComponent implements OnInit {
  @Input() routes: any[];

  constructor(
    private router: Router
  ) { }

  ngOnInit() {
  }

  isActiveRoute(url: string) {
    return this.router.url.indexOf(url) !== -1;
  }

}
