import { Injectable, NgZone } from '@angular/core';
import { MatSnackBar } from '@angular/material/snack-bar';
import { TranslateService } from '@ngx-translate/core';

@Injectable({
  providedIn: 'root'
})
export class NotificationService {
  constructor(
    private snackBar: MatSnackBar,
    private ngZone: NgZone,
    private translateService: TranslateService,
  ) { }

  notify(m: string, c: string, returnValue?: any) {
    const message = this.translateService.instant(m);
    this.ngZone.run(_ => this.snackBar.open(message, '', {
      // panelClass: [`bg-${c}`, 'gradient-light'],
      panelClass: c,
      // horizontalPosition: 'right',
      // verticalPosition: 'top'
    }));
    if (returnValue !== undefined) return returnValue;
  }

  error(m: string, returnValue?: any) {
    return this.notify(m, 'error', returnValue);
  }

  success(m: string, returnValue?: any) {
    return this.notify(m, 'success', returnValue);
  }

  warn(m: string, returnValue?: any) {
    return this.notify(m, 'warn', returnValue);
  }
}
