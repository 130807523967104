export enum Language {
	zh = "zh", // Chinese
	en = "en", // English

	af = "af", // Afrikaans
	sq = "sq", // Albanian
	am = "am", // Amharic
	ar = "ar", // Arabic
	hy = "hy", // Armenian
	az = "az", // Azerbaijani
	eu = "eu", // Basque
	be = "be", // Belarusian
	bn = "bn", // Bengali
	bs = "bs", // Bosnian
	bg = "bg", // Bulgarian
	ca = "ca", // Catalan
	ceb = "ceb", // Cebuano
	ny = "ny", // Chichewa
	co = "co", // Corsican
	hr = "hr", // Croatian
	cs = "cs", // Czech
	da = "da", // Danish
	nl = "nl", // Dutch
	eo = "eo", // Esperanto
	et = "et", // Estonian
	tl = "tl", // Filipino
	fi = "fi", // Finnish
	fr = "fr", // French
	fy = "fy", // Frisian
	gl = "gl", // Galician
	ka = "ka", // Georgian
	de = "de", // German
	el = "el", // Greek
	gu = "gu", // Gujarati
	ht = "ht", // Haitian Creole
	ha = "ha", // Hausa
	haw = "haw", // Hawaiian
	iw = "iw", // Hebrew
	hi = "hi", // Hindi
	hmn = "hmn", // Hmong
	hu = "hu", // Hungarian
	is = "is", // Icelandic
	ig = "ig", // Igbo
	id = "id", // Indonesian
	ga = "ga", // Irish
	it = "it", // Italian
	ja = "ja", // Japanese
	jw = "jw", // Javanese
	kn = "kn", // Kannada
	kk = "kk", // Kazakh
	km = "km", // Khmer
	rw = "rw", // Kinyarwanda
	ko = "ko", // Korean
	ku = "ku", // Kurdish (Kurmanji)
	ky = "ky", // Kyrgyz
	lo = "lo", // Lao
	la = "la", // Latin
	lv = "lv", // Latvian
	lt = "lt", // Lithuanian
	lb = "lb", // Luxembourgish
	mk = "mk", // Macedonian
	mg = "mg", // Malagasy
	ms = "ms", // Malay
	ml = "ml", // Malayalam
	mt = "mt", // Maltese
	mi = "mi", // Maori
	mr = "mr", // Marathi
	mn = "mn", // Mongolian
	my = "my", // Myanmar (Burmese)
	ne = "ne", // Nepali
	no = "no", // Norwegian
	or = "or", // Odia (Oriya)
	ps = "ps", // Pashto
	fa = "fa", // Persian
	pl = "pl", // Polish
	pt = "pt", // Portuguese
	pa = "pa", // Punjabi
	ro = "ro", // Romanian
	ru = "ru", // Russian
	sm = "sm", // Samoan
	gd = "gd", // Scots Gaelic
	sr = "sr", // Serbian
	st = "st", // Sesotho
	sn = "sn", // Shona
	sd = "sd", // Sindhi
	si = "si", // Sinhala
	sk = "sk", // Slovak
	sl = "sl", // Slovenian
	so = "so", // Somali
	es = "es", // Spanish
	su = "su", // Sundanese
	sw = "sw", // Swahili
	sv = "sv", // Swedish
	tg = "tg", // Tajik
	ta = "ta", // Tamil
	tt = "tt", // Tatar
	te = "te", // Telugu
	th = "th", // Thai
	tr = "tr", // Turkish
	tk = "tk", // Turkmen
	uk = "uk", // Ukrainian
	ur = "ur", // Urdu
	ug = "ug", // Uyghur
	uz = "uz", // Uzbek
	vi = "vi", // Vietnamese
	cy = "cy", // Welsh
	xh = "xh", // Xhosa
	yi = "yi", // Yiddish
	yo = "yo", // Yoruba
	zu = "zu", // Zulu
	he = "he", // Hebrew
}

export const SupportedLanguages = [Language.en, Language.zh];

export enum Timezone {
	HK = "Asia/Hong_Kong",
}

export const Translations = {
	[Language.en]: {
		general: {
			customer: "Customer",
			timestamp: "Timestamp",
			submission_time: "Submission Time",
			contact: "Contact",
			name: "Name",
			channel: "Channel",
		},
		notification: {
			new_message: {
				title: "New message",
				body: "You receive a new message...",
			},
			new_task: {
				title: "New task",
				body: "A new task is added",
			},
			whatsapp_ERROR: {
				title: "WhatsApp Disconnected",
				body: "WhatsApp is disconnected. Please reconnect now!",
			},
			whatsapp_PENDING: {
				title: "WhatsApp Ready",
				body:
					"WhatsApp is ready to connect. Please scan the QR code and connect in 15 minutes",
			},
			whatsapp_CONNECTED: {
				title: "WhatsApp Connected",
				body:
					"WhatsApp is connected. Please keep your mobile phone and network on",
			},
			whatsapp_CLOSED: {
				title: "WhatsApp Closed",
				body: "WhatsApp is closed.",
			},
		},
		chatform: {
			controls:
				"ℹ️Enter: \n'Undo'➡️Go back to previous question; \n'Skip'➡️Skip current question;\n'Restart'➡️Back to the first question; \n'Cancel'➡️Skip all questions;",
			verify: "Did you enter it wrong?",
			required: "This question is required.",
			confirmation: "Confirmation is required.",
			undo: "Undo",
			skip: "Skip",
			cancel: "Cancel",
			restart: "Restart",
			confirm: "Confirm",
			yes: "Yes",
			no: "No",
			option_instruction: "enter answer no.",
			checkbox_instruction: "enter answer no., seperate by ','",
			number_instruction: "numbers only",
		},
	},
	[Language.zh]: {
		general: {
			customer: "客戶",
			timestamp: "時間",
			submission_time: "提交時間",
			contact: "聯絡方式",
			name: "姓名",
			channel: "來源",
		},
		notification: {
			new_message: {
				title: "新信息",
				body: "你收到一個新訊息...",
			},
			new_task: {
				title: "新任務",
				body: "已新增一個新任務...",
			},
			whatsapp_ERROR: {
				title: "WhatsApp連接已斷開",
				body: "WhatsApp連接已斷開。請馬上重新連接",
			},
			whatsapp_PENDING: {
				title: "WhatsApp已準備好",
				body: "WhatsApp已準備好連接。請於15分鐘内掃描QR碼連接",
			},
			whatsapp_CONNECTED: {
				title: "WhatsApp已連接",
				body: "WhatsApp已連接。請保持您的手提電話及網絡連接開啓",
			},
			whatsapp_CLOSED: {
				title: "WhatsApp已關閉",
				body: "WhatsApp已關閉",
			},
		},
		chatform: {
			controls:
				"ℹ️輸入: \n'回上題'➡️回到上題; \n'略過'➡️略過此題; \n'重新開始'➡️回到第一題; \n'取消'➡️略過所有;",
			verify: "是不是輸入錯了呢?",
			required: "需要回答此問題才能繼續。",
			confirmation: "需要確認才能繼續。",
			undo: "回上題",
			skip: "略過",
			cancel: "取消",
			restart: "重新開始",
			confirm: "確定",
			yes: "是",
			no: "否",
			option_instruction: "輸入答案編號即可",
			checkbox_instruction: "輸入答案編號, 以','分隔",
			number_instruction: "請輸入數字",
		},
	},
};
