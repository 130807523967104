<div class="page__content">
  <div class="setting" *ngIf="admin" [@smooth]>

    <div class="setting__user">
      <div class="setting__user__photo" (mouseenter)="showEditPhoto = true" (mouseleave)="showEditPhoto = false">
        <div class="user-icon gradient-theme" *ngIf="!admin.info.photoURL">
          {{admin.info.displayName ? admin.info.displayName[0] : admin.info.email[0]}}
        </div>
        <div class="user-icon" *ngIf="admin.info.photoURL" [style.background-image]="userPhoto()">
        </div>
        <button class="icon-button" [class.invisible]="!showEditPhoto" (click)="uploadUserPhoto()">
          <i class="material-icons">edit</i></button>
      </div>

      <input id="upload-photo" class="hidden" type="file" (change)="onFileChange($event)" accept="image/*">

      <div class="setting__user__info">
        <div class="setting__user__info__name">
          <input type="text" class="name" [(ngModel)]="admin.info.displayName" placeholder="display name"
            [readonly]="!editName">

          <button class="icon-button" (click)="editName=!editName" *ngIf="!editName">
            <i class="material-icons">edit</i>
          </button>

          <button class="icon-button warn"
            (click)="admin.info.displayName = adminCopy.info.displayName; editName=false;" *ngIf="editName">
            <i class="material-icons">cancel</i>
          </button>
          <button class="icon-button success" (click)="save()"
            *ngIf="admin.info.displayName !== adminCopy.info.displayName">
            <i class="material-icons">check_circle</i></button>

        </div>
        <input type="email" class="setting__user__info__email" [value]="admin.info.email" readonly>
      </div>
    </div>

    <div class="form">
      <div class="form__section">
        <span translate>setting.change-password</span>
      </div>

      <div class="form__row">
        <div class="form__label">
          <i class="material-icons">lock</i>
          <span translate>auth.new-password</span>
        </div>
        <div class="form__field">
          <input type="password" [(ngModel)]="password" autocomplete="new-password">
        </div>
      </div>
      <div class="form__row">
        <div class="form__label">
          <i class="material-icons">lock</i>
          <span translate>auth.confirm-password</span>
        </div>
        <div class="form__field">
          <input type="password" [(ngModel)]="confirmPassword" autocomplete="new-password">
        </div>
        <div class="form__action">
          <button class="icon-button warn" (click)="password = ''; confirmPassword = '';"
            *ngIf="password !=='' || confirmPassword !== ''">
            <i class="material-icons">cancel</i>
          </button>
          <button class="icon-button success" (click)="changePassword()"
            *ngIf="password !=='' && password == confirmPassword">
            <i class="material-icons">check_circle</i>
          </button>
        </div>
      </div>

      <div class="form__section" translate>
        setting.display
      </div>

      <div class="form__row">
        <div class="form__label">
          <i class="material-icons">brightness_4</i>
          <span translate>setting.darkmode</span>
        </div>
        <div class="form__field">
          <mat-slide-toggle [(ngModel)]="darkmode" (ngModelChange)="toggleDarkmode()">
          </mat-slide-toggle>
        </div>
      </div>

    </div>


  </div>

  <app-loader *ngIf="!admin"></app-loader>
</div>