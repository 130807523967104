<div class="page" *ngIf="admin">
  <div class="page__header">
    <div class="page__header__title">
      <i class="material-icons">settings</i>
      <label translate>nav.setting</label>
    </div>

    <div class="page__header__tab">
      <a [class.selected]="isActiveRoute('/setting/system')" [routerLink]="['system']">
        <div class="menu-title" translate>nav.system</div>
      </a>

      <a [class.selected]="isActiveRoute('/setting/builtin')" [routerLink]="['builtin']">
        <div class="menu-title" translate>nav.builtin</div>
      </a>

      <a [class.selected]="isActiveRoute('/setting/stopword')" [routerLink]="['stopword']">
        <div class="menu-title" translate>nav.stopword</div>
      </a>

      <a [class.selected]="isActiveRoute('/setting/verbal')" [routerLink]="['verbal']">
        <div class="menu-title" translate>nav.verbal</div>
      </a>

      <a [class.selected]="isActiveRoute('/setting/account')" [routerLink]="['account']">
        <div class="menu-title" translate>nav.account</div>
      </a>
    </div>
  </div>

  <router-outlet></router-outlet>


</div>