import { Injectable } from '@angular/core';
import * as firebase from 'firebase/app';
import 'firebase/firestore';
import { BehaviorSubject } from 'rxjs';
import { to } from '@utility';
import { Language, SupportedLanguages } from '@models/misc';
import { ApiService } from './api.service';
import { environment } from '../../environments/environment';
import { NotificationService } from '@shared';
import { AuthService } from './auth.service';


@Injectable({
  providedIn: 'root'
})
export class NlpService {
  private _collection = firebase.firestore().collection(environment.CONFIG_COLLECTION);
  private _stopword: any;
  private _verbal: any;
  private _system: any;
  private _stopword$: BehaviorSubject<any> = new BehaviorSubject(null);
  public stopwordChanges = this._stopword$.asObservable();
  stopwordUnsub: any;
  stopwordWhitelistUnsub: any;
  private _verbal$: BehaviorSubject<any> = new BehaviorSubject(null);
  public verbalChanges = this._verbal$.asObservable();
  verbalUnsub: any;

  private _system$: BehaviorSubject<any> = new BehaviorSubject(null);
  public systemChanges = this._system$.asObservable();
  systemUnsub: any;
  languages = SupportedLanguages;
  knowledgeWords: any;
  stopwordWhitelist: string[];


  constructor(
    private authService: AuthService,
    private notificationService: NotificationService,
    private apiService: ApiService,
  ) {
    this.authService.authUserChanges.subscribe(authUser => {
      if (!authUser) {
        if (this.stopwordUnsub) this.stopwordUnsub();
        if (this.stopwordWhitelistUnsub) this.stopwordWhitelistUnsub();
        if (this.verbalUnsub) this.verbalUnsub();
        if (this.systemUnsub) this.systemUnsub();
      }
    });
  }

  async getKnowledgeWords() {
    if (!this.knowledgeWords) this.knowledgeWords = await this.apiService.listKnowledgeWords();
    if (!this.stopwordWhitelist) await this.listenStopwordWhitelist();
    // console.log(this.stopwordWhitelist);
    this.languages.forEach(lang => this.knowledgeWords[lang] = this.knowledgeWords[lang].filter(w => this._stopword[lang].indexOf(w) === -1 && this.stopwordWhitelist.indexOf(w) === -1).sort().sort((a, b) => a.length - b.length));
  }

  listenStopword() {
    if (this.stopwordUnsub) this.stopwordUnsub();
    this.stopwordUnsub = this._collection.doc(environment.STOPWORD_DOCUMENT).onSnapshot(
      async doc => {
        if (doc.exists) {
          this._stopword = doc.data();
          this.languages.forEach(lang => this._stopword[lang].sort().sort((a, b) => a.length - b.length));
        } else {
          this._stopword = {};
          this.languages.forEach(lang => this._stopword[lang] = []);
          await this.setStopword(this._stopword);
        }
        await this.getKnowledgeWords();
        this._stopword$.next({ stopword: this._stopword, knowledgeWords: this.knowledgeWords, whitelist: this.stopwordWhitelist });
      },
      err => {
        console.log(err);
      }
    );
  }

  listenStopwordWhitelist() {
    return new Promise(resolve => {
      if (this.stopwordWhitelistUnsub) this.stopwordWhitelistUnsub();
      this.stopwordWhitelistUnsub = this._collection.doc(environment.STOPWORD_WHITELIST_DOCUMENT).onSnapshot(
        async doc => {
          if (doc.exists) {
            this.stopwordWhitelist = doc.data().words;
          } else {
            this.stopwordWhitelist = [];
            await this.setStopwordWhitelist(this.stopwordWhitelist);
          }
          resolve(this.stopwordWhitelist);
        },
        err => {
          console.log(err);
        }
      );
    });
  }

  listenVerbal() {
    if (this.verbalUnsub) this.verbalUnsub();
    this.verbalUnsub = this._collection.doc(environment.VERBAL_DOCUMENT).onSnapshot(
      async doc => {
        if (doc.exists) {
          this._verbal = doc.data();
          this._verbal.word.sort().sort((a, b) => a.length - b.length);
        } else {
          this._verbal = { word: [], translation: {} };
          await this.setVerbal(this._verbal);
        }
        this._verbal$.next(this._verbal);
      },
      err => {
        console.log(err);
      }
    );
  }

  listenSystem() {
    if (this.systemUnsub) this.systemUnsub();
    this.systemUnsub = this._collection.doc(environment.SYSTEM_DOCUMENT).onSnapshot(
      async doc => {
        if (doc.exists) {
          this._system = doc.data();
        } else {
          this._system = { threshold: 0.8, active: true, options: 5 };
          await this.setSystem(this._system);
        }
        this._system$.next(this._system);
      },
      err => {
        console.log(err);
      }
    );
  }

  async setSystem(update: any) {
    const [updateErr, updateRes] = await to(this._collection.doc(environment.SYSTEM_DOCUMENT).set(update));
    if (updateErr) return this.notificationService.error('error.data.update', false);
    return true;
  }

  async updateSystem(update: any) {
    const [updateErr, updateRes] = await to(this._collection.doc(environment.SYSTEM_DOCUMENT).update(update));
    if (updateErr) return this.notificationService.error('error.data.update', false);
    return true;
  }

  async setStopword(update: any) {
    const [updateErr, updateRes] = await to(this._collection.doc(environment.STOPWORD_DOCUMENT).set(update));
    if (updateErr) return this.notificationService.error('error.data.update', false);
    return true;
  }

  async setStopwordWhitelist(words: string[]) {
    const [updateErr, updateRes] = await to(this._collection.doc(environment.STOPWORD_WHITELIST_DOCUMENT).set({ words }));
    if (updateErr) return this.notificationService.error('error.data.update', false);
    return true;
  }

  async addStopwordWhitelist(word: string) {
    const [updateErr, updateRes] = await to(this._collection.doc(environment.STOPWORD_WHITELIST_DOCUMENT).update({ words: firebase.firestore.FieldValue.arrayUnion(word) }));
    if (updateErr) return this.notificationService.error('error.data.update', false);
    await this.getKnowledgeWords();
    return true;
  }

  async addStopword(lang: Language, newStopword: string) {
    const update = {};
    update[lang] = firebase.firestore.FieldValue.arrayUnion(newStopword);
    const [updateErr, updateRes] = await to(this._collection.doc(environment.STOPWORD_DOCUMENT).update(update));
    if (updateErr) return this.notificationService.error('error.data.update', false);
    return true;
  }

  async deleteStopword(lang: Language, word: string) {
    const update = {};
    update[lang] = firebase.firestore.FieldValue.arrayRemove(word);
    const [updateErr, updateRes] = await to(this._collection.doc(environment.STOPWORD_DOCUMENT).update(update));
    if (updateErr) return this.notificationService.error('error.data.update', false);
    return true;
  }

  async setVerbal(update: any) {
    const [updateErr, updateRes] = await to(this._collection.doc(environment.VERBAL_DOCUMENT).set(update));
    if (updateErr) return this.notificationService.error('error.data.update', false);
    return true;
  }

  async addVerbalWord(newWord: string) {
    const [updateErr, updateRes] = await to(this._collection.doc(environment.VERBAL_DOCUMENT).update({
      word: firebase.firestore.FieldValue.arrayUnion(newWord)
    }));
    if (updateErr) return this.notificationService.error('error.data.update', false);
    return true;
  }

  async deleteVerbalWord(word: string) {
    const [updateErr, updateRes] = await to(this._collection.doc(environment.VERBAL_DOCUMENT).update({
      word: firebase.firestore.FieldValue.arrayRemove(word)
    }));
    if (updateErr) return this.notificationService.error('error.data.update', false);
    return true;
  }

  async addVerbalTranslation(origin: string, translation: string) {
    const update = {};
    update[`translation.${origin}`] = translation;
    const [updateErr, updateRes] = await to(this._collection.doc(environment.VERBAL_DOCUMENT).update(update));
    if (updateErr) return this.notificationService.error('error.data.update', false);
    return true;
  }

  async deleteVerbalTranslation(origin: string) {
    const update = {};
    update[`translation.${origin}`] = firebase.firestore.FieldValue.delete();
    const [updateErr, updateRes] = await to(this._collection.doc(environment.VERBAL_DOCUMENT).update(update));
    if (updateErr) return this.notificationService.error('error.data.update', false);
    return true;
  }

}
