import { Injectable } from "@angular/core";
import { NotificationService, FirestoreService } from "@shared";
import { to, mask } from "@utility";
import * as firebase from "firebase/app";
import "firebase/storage";
import { AuthService } from "./auth.service";
import { Admin } from "@models/user";
import { environment } from "../../environments/environment";
const PRODUCTION = environment.ENV === "PROD";

@Injectable({
  providedIn: "root",
})
export class StorageService {
  private _storage: firebase.storage.Storage;
  private _storageRoot: firebase.storage.Reference;
  private _admin: Admin;

  constructor(
    private notificationService: NotificationService,
    private firestoreService: FirestoreService,
    private authService: AuthService
  ) {
    this._storage = firebase.storage();
    this._storageRoot = this._storage.ref();
    this.authService.adminChanges.subscribe((admin) => (this._admin = admin));
  }

  loadJSON(event: any): Promise<any> {
    return new Promise((resolve) => {
      const file = event.target.files[0];
      if (!file) resolve(null);
      if (file.type !== "application/json") {
        this.notificationService.error("error.file.json-only");
        resolve(null);
      }
      const reader = new FileReader();
      reader.onload = (e: any) => {
        const json = JSON.parse(String(e.target.result));
        resolve(json);
      };
      reader.onerror = (e) => {
        reader.abort();
        this.notificationService.error("error.file.read");
        resolve(null);
      };
      reader.readAsText(file);
    });
  }

  async uploadUserPhoto(file: File): Promise<string> {
    const location = this.firestoreService.urlToLocation(
      this._admin.info.photoURL
    );
    if (location) await this.firestoreService.delete(location);

    const [uploadErr, attachment] = await to(
      this.firestoreService.upload(
        this._admin.id,
        file,
        PRODUCTION,
        null,
        "profile",
        true
      )
    );
    if (uploadErr || !attachment) return null;
    return attachment.url;
  }
}
