import { Component } from '@angular/core';
import { MatDialogRef } from '@angular/material/dialog';
import { SupportedLanguages } from '@models/misc';
import { BuiltIn } from '@models/knowledge';

@Component({
  selector: 'app-add-builtin',
  templateUrl: './add-builtin.component.html',
  styleUrls: ['./add-builtin.component.scss']
})
export class AddBuiltinComponent {
  builtinId = '';
  locales = {};
  languages = SupportedLanguages;
  default = false;
  sample = '';

  constructor(
    public dialogRef: MatDialogRef<AddBuiltinComponent>) {
    this.languages.forEach(lang => this.locales[lang] = '');
  }

  close() {
    this.dialogRef.close(false);
  }

  valid() {
    if (this.builtinId === '') return false;
    if (this.sample === '') return false;
    for (let lang of this.languages) {
      if (this.locales[lang] === '') return false;
    }
    return true;
  }

  submit() {
    if (this.valid()) {
      const id = `friday_${this.builtinId}`;
      return {
        builtin: new BuiltIn({
          id,
          locales: this.locales,
          default: this.default
        }),
        sample: { [id]: [this.sample] }
      }
    }
    return null;
  }

}
