import { Component, OnInit } from "@angular/core";
import { trigger, transition, style, animate } from "@angular/animations";
import * as firebase from "firebase/app";
import "firebase/auth";
import { environment } from "../environments/environment";
import { SettingService, FcmService } from "@shared";

@Component({
  selector: "app-root",
  templateUrl: "./app.component.html",
  styleUrls: ["./app.component.scss"],
})
export class AppComponent implements OnInit {
  initialized = false;

  constructor(
    private settingService: SettingService,
    private fcmService: FcmService
  ) {}

  ngOnInit() {
    firebase.initializeApp(environment.firebase);
    firebase
      .auth()
      .setPersistence(firebase.auth.Auth.Persistence.LOCAL)
      .catch((error) => console.log("[Set Auth Persistence Error]", error));
    this.settingService.initialize();
    this.fcmService.initialize(environment.fcmKey, environment.mobile);
    this.initialized = true;
  }
}
