import { Component, OnInit, Inject } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { UserType } from '@models/user';
import { ApiService } from '../../../services/api.service';
import { NotificationService, ConfirmationDialogComponent } from '@shared';



@Component({
  selector: 'app-add-user',
  templateUrl: './add-user.component.html',
  styleUrls: ['./add-user.component.scss']
})
export class AddUserComponent implements OnInit {
  type: UserType;
  email = '';
  password = '';
  confirmPassword = '';
  displayName = '';
  permission: number;
  UserType: typeof UserType = UserType;
  loading = false;

  constructor(
    public dialogRef: MatDialogRef<ConfirmationDialogComponent>,
    private apiService: ApiService,
    private notificationService: NotificationService,
    @Inject(MAT_DIALOG_DATA) public data: any
  ) { }

  ngOnInit() {
    this.type = this.data.type;
  }

  close(succeed = false) {
    this.dialogRef.close(succeed);
  }

  valid() {
    if (this.email === '' || this.password === '' || this.confirmPassword === '') return false;
    return true;
  }

  async createUser() {
    if (this.password !== this.confirmPassword) return this.notificationService.error('error.auth.password-mismatch', false);
    this.loading = true;
    switch (this.type) {
      case UserType.Client:
        await this.apiService.createClient(this.email, this.password, this.displayName, this.permission);
        break;
      case UserType.Admin:
        await this.apiService.createAdmin(this.email, this.password, this.displayName, this.permission);
        break;
    }
    this.close(true);
  }

}
