import { Component, OnInit, OnDestroy } from "@angular/core";
import { Admin } from "@models/user";
import { Subscription } from "rxjs";
import * as firebase from "firebase/app";
import { AuthService } from "../../services/auth.service";
import { AdminService } from "../../services/admin.service";
import { FcmService } from "@shared";

@Component({
  selector: "app-container",
  templateUrl: "./container.component.html",
  styleUrls: ["./container.component.scss"],
})
export class ContainerComponent implements OnInit, OnDestroy {
  admin: Admin;
  adminSub: Subscription;
  initialized = false;
  routes = [
    // { name: "dashboard", url: "/dashboard", icon: "dashboard" },
    { name: "task", url: "/task", icon: "headset_mic" },
    { name: "user", url: "/user", icon: "people" },
    { name: "setting", url: "/setting", icon: "settings" },
  ];

  constructor(
    public authService: AuthService,
    private adminService: AdminService,
    private fcmService: FcmService
  ) {}

  ngOnInit() {
    this.adminSub = this.authService.adminChanges.subscribe(async (admin) => {
      this.admin = admin;
      if (admin) {
        if (!this.initialized) {
          const device = await this.fcmService.getNotificationToken();
          // console.log(device);
          if (
            device &&
            device.name &&
            device.token &&
            !this.admin.settings.notification.devices.some(
              (d) => d.token === device.token
            )
          ) {
            await this.adminService.updateAdmin({
              "settings.notification.devices": firebase.firestore.FieldValue.arrayUnion(
                { name: device.name, token: device.token, active: true }
              ),
            });
          }
        }
        this.initialized = true;
      }
    });
  }

  ngOnDestroy() {
    if (this.adminSub) this.adminSub.unsubscribe();
  }
}
