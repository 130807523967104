import { Component, OnInit, OnDestroy } from "@angular/core";
import { Subscription } from "rxjs";
import { TranslateService } from "@ngx-translate/core";
import { SupportedLanguages } from "@models/misc";
import { MatDialog } from "@angular/material/dialog";
import { BuiltIn } from "@models/knowledge";
import { marker as _ } from "@biesbjerg/ngx-translate-extract-marker";
import {
  stringToDate,
  removeElementByIndex,
  unique,
  formatDate,
} from "@utility";
import { BuiltinService } from "../../../services/builtin.service";
import { ApiService } from "../../../services/api.service";
import { NotificationService } from "@shared";
import { StorageService } from "../../../services/storage.service";
import { AddBuiltinComponent } from "../../dialogs/add-builtin/add-builtin.component";
import { ConfirmationDialogComponent } from "@shared";

@Component({
  selector: "app-builtin",
  templateUrl: "./builtin.component.html",
  styleUrls: ["./builtin.component.scss"],
})
export class BuiltinComponent implements OnInit, OnDestroy {
  builtinSub: Subscription;
  builtins: BuiltIn[];
  builtinSamples: any;
  languages = SupportedLanguages;
  newSamples = {};
  edited = false;
  builtinIds: string[];
  initialized = false;
  trained = true;
  schedule: string;
  loading = false;

  constructor(
    private translateService: TranslateService,
    private builtinService: BuiltinService,
    private apiService: ApiService,
    public dialog: MatDialog,
    private notificationService: NotificationService,
    private storageService: StorageService
  ) {}

  ngOnInit() {
    this.builtinService.listenBuiltins();

    this.builtinSub = this.builtinService.builtinChanges.subscribe(
      async (builtins) => {
        if (builtins) {
          // console.log(JSON.stringify(this.builtins), JSON.stringify(builtins));
          if (!this.builtins) {
            this.builtins = builtins;
          } else {
            builtins.forEach((b) => {
              const i = this.builtins.findIndex((n) => n.id === b.id);
              if (i === -1) {
                this.builtins.push(b);
              } else {
                this.builtins[i].default = b.default;
                this.builtins[i].locales = b.locales;
                if (b.query) this.builtins[i].query = b.query;
                if (b.order) this.builtins[i].order = b.order;
              }
            });
          }

          this.builtinIds = this.builtins.map((b) => b.id);
          await this.refresh();
          this.initialized = true;
        }
      }
    );
  }

  ngOnDestroy() {
    if (this.edited) this.saveAll();
  }

  async getWitStatus() {
    const info = await this.builtinService.getWitStatus();
    this.trained = info.training_status === "done" || false;
    this.schedule = this.trained
      ? "Last trained at " + this.toTime(info.last_trained_at)
      : info.training_status === "ongoing"
      ? "Training"
      : "Will train at " + this.toTime(info.will_train_at);
  }

  async refresh() {
    this.builtinIds.forEach((id) => (this.newSamples[id] = ""));
    this.builtinSamples = await this.builtinService.getSamples();
    await this.getWitStatus();
  }

  triggerLoadJSON() {
    const element = document.getElementById("load-json") as HTMLInputElement;
    element.value = null;
    element.click();
  }

  async loadJSON(event) {
    const json = await this.storageService.loadJSON(event);
    if (!json) return false;
    console.log(json);
    this.loading = true;
    const newBuiltins: BuiltIn[] = [];
    const newQueries = {};
    for (const id in json) {
      json[id].samples = unique(
        [...[json[id].query], ...json[id].samples].map((s) => s.toLowerCase())
      );
      if (!this.builtinIds.includes(id)) {
        newBuiltins.push(new BuiltIn(json[id]));
        newQueries[id] = [json[id].query];
      } else {
        const builtin = this.builtins.find((b) => b.id === id);
        if (builtin) {
          builtin.query = json[id].query;
        }
      }
    }

    if (newBuiltins.length > 0)
      await this.apiService.addBuiltins(newBuiltins, newQueries);

    for (const id in json) {
      if (!json[id].samples) continue;
      const newSamples = json[id].samples.filter(
        (s) =>
          !this.builtinSamples[id] || !this.builtinSamples[id].kb.includes(s)
      );
      await this.apiService.trainBuiltin(id, newSamples);
    }
    this.saveAll();
    setTimeout(() => this.refresh(), 2000);
    this.loading = false;
    return true;
  }

  export() {
    const json = {};
    for (const id of this.builtinIds) {
      const builtin = this.builtins.find((b) => b.id === id);
      if (!builtin) continue;
      json[id] = builtin.toObject();
      json[id].samples = this.builtinSamples[id].kb;
    }
    // console.log(json);
    const download = document.getElementById("export");
    download.setAttribute(
      "href",
      "data:text/plain;charset=utf-8," +
        encodeURIComponent(JSON.stringify(json))
    );
    const t = new Date().toISOString().substr(0, 16).replace(/[-:]/g, "");
    download.setAttribute("download", `friday_builtin_${t}.json`);
    download.click();
  }

  addBuiltin() {
    const dialogRef = this.dialog.open(AddBuiltinComponent);

    dialogRef.afterClosed().subscribe(async (data) => {
      if (data) {
        data.builtin.order = this.builtinIds.length + 1;
        console.log(data.builtin);
        await this.apiService.addBuiltins([data.builtin], data.sample);
        // setTimeout(() => this.refresh(), 2000);
      }
    });
  }

  deleteBuiltin(id: string) {
    const dialogRef = this.dialog.open(ConfirmationDialogComponent, {
      data: {
        messageHTML: `<small class="warning"><i class="material-icons">warning</i>${this.translateService.instant(
          "dialog.warning.cannot-undone"
        )}</small> <div>${this.translateService.instant(
          "dialog.warning.confirm-delete"
        )}<span class="highlight">${id}</span>? </div> `,
        destructive: true,
      },
    });

    dialogRef.afterClosed().subscribe(async (confirmed) => {
      if (confirmed) {
        const res = await this.apiService.deleteBuiltin(id);
        // setTimeout(() => this.refresh(), 2000);
        return true;
      }
      return false;
    });
  }

  showWarning(id: string, sample: string): boolean {
    return this.builtinSamples[id].wit.indexOf(sample) === -1;
  }

  attention(id: string) {
    // return this.builtinSamples[id].kb.length !== this.builtinSamples[id].wit.length || this.builtinRequests[id].length > 0;
    return false;
  }

  toTime(timeString: string): string {
    return formatDate(stringToDate(timeString));
  }

  async saveAll() {
    const promiseArr = this.builtins.map((b) =>
      this.builtinService.update(b.id, b.toObject())
    );
    await Promise.all(promiseArr);
    this.edited = false;
  }

  async retrainSample(knowledgeId: string, sample: string) {
    const succeed = await this.apiService.trainBuiltin(knowledgeId, [sample]);
    if (succeed) {
      if (this.builtinSamples[knowledgeId].kb.indexOf(sample) == -1)
        this.builtinSamples[knowledgeId].kb.push(sample);
      if (this.builtinSamples[knowledgeId].wit.indexOf(sample) == -1)
        this.builtinSamples[knowledgeId].wit.push(sample);
      // setTimeout(() => this.refresh(), 2000);
    }
  }

  // async retrainAll() {
  //   let samples = {};
  //   for (let id of this.builtinIds) {
  //     for (let sample of this.builtinSamples[id].all) {
  //       if (this.showWarning(id, sample)) samples[id] = samples[id] ? [...samples[id], ...[sample]] : [sample];
  //     }
  //   }
  //   console.log(samples);
  //   const promiseArr = Object.keys(samples).map(id => this.apiService.trainBuiltin(id, samples[id]));
  //   if (promiseArr.length == 0) return false;
  //   const [err, res] = await to(Promise.all(promiseArr));
  //   if (err) return false;
  //   for (let id of this.builtinIds) {
  //     for (let sample of this.builtinSamples[id].all) {
  //       if (this.showWarning(id, sample)) {
  //         if (this.builtinSamples[id].kb.indexOf(sample) == -1) this.builtinSamples[id].kb.push(sample);
  //         if (this.builtinSamples[id].wit.indexOf(sample) == -1) this.builtinSamples[id].wit.push(sample);
  //       }
  //     }
  //   }
  //   // setTimeout(() => this.refresh(), 2000);
  //   return true;
  // }

  // async retrainBuiltin(id: string) {
  //   let samples = [];
  //   for (let sample of this.builtinSamples[id].all) {
  //     if (this.showWarning(id, sample)) samples.push(sample);
  //   }
  //   await this.apiService.trainBuiltin(id, samples);
  //   for (let sample of this.builtinSamples[id].all) {
  //     if (this.showWarning(id, sample)) {
  //       if (this.builtinSamples[id].kb.indexOf(sample) == -1) this.builtinSamples[id].kb.push(sample);
  //       if (this.builtinSamples[id].wit.indexOf(sample) == -1) this.builtinSamples[id].wit.push(sample);
  //     }
  //   }
  //   // setTimeout(() => this.refresh(), 2000);
  //   return true;
  // }

  // async confirmRequest(request: BuiltinRequest) {
  //   const dialogRef = this.dialog.open(ConfirmationDialogComponent, {
  //     data: {
  //       messageHTML: `<div>${this.translateService.instant('dialog.warning.confirm-add')}<span class="highlight">${request.query}</span>? </div> `,
  //     }
  //   });

  //   dialogRef.afterClosed().subscribe(async confirmed => {
  //     if (confirmed) {
  //       const added = await this.apiService.submitBuiltinRequest(request, true);
  //       if (added) {
  //         this.builtinSamples[request.knowledgeId].all.push(request.query);
  //         this.builtinSamples[request.knowledgeId].kb.push(request.query);
  //         this.builtinSamples[request.knowledgeId].wit.push(request.query);
  //         // setTimeout(() => this.refresh(), 2000);
  //       }
  //     }
  //     return true;
  //   });
  // }

  // async dismissRequest(request: BuiltinRequest) {
  //   return await this.apiService.submitBuiltinRequest(request, false);
  // }

  async addSample(id: string) {
    console.log(id);
    const sample = this.newSamples[id];
    // if (sample == '' || this.builtinSamples[id].kb.indexOf(sample) !== -1) {
    //   this.newSamples[id] = '';
    //   const m = _('error.sample.existing');
    //   return this.notificationService.notifyError(null, this.translateService.instant(m), false);
    // }

    const dialogRef = this.dialog.open(ConfirmationDialogComponent, {
      data: {
        messageHTML: `<div>${this.translateService.instant(
          "dialog.warning.confirm-add"
        )}<span class="highlight">${sample}</span>? </div> `,
      },
    });

    dialogRef.afterClosed().subscribe(async (confirmed) => {
      if (confirmed) {
        const res = await this.apiService.trainBuiltin(id, [sample]);
        if (res) {
          this.builtinSamples[id].kb.push(sample);
          console.log(this.builtinSamples[id].kb);
          this.newSamples[id] = "";
          setTimeout(() => this.refresh(), 2000);
          return true;
        }
      }
      this.newSamples[id] = "";
      return false;
    });
  }

  async deleteSample(knowledgeId: string, sample: string) {
    console.log(knowledgeId, sample);
    const dialogRef = this.dialog.open(ConfirmationDialogComponent, {
      data: {
        messageHTML: `<small class="warning"><i class="material-icons">warning</i>${this.translateService.instant(
          "dialog.warning.cannot-undone"
        )}</small> <div>${this.translateService.instant(
          "dialog.warning.confirm-delete"
        )}<span class="highlight">${sample}</span>? </div> `,
        destructive: true,
      },
    });

    dialogRef.afterClosed().subscribe(async (confirmed) => {
      if (confirmed) {
        const res = await this.apiService.deleteBuiltinSample(
          knowledgeId,
          sample
        );
        if (res) {
          console.log(this.builtinSamples);
          this.builtinSamples[knowledgeId].kb = removeElementByIndex(
            this.builtinSamples[knowledgeId].kb,
            this.builtinSamples[knowledgeId].kb.findIndex((s) => s === sample)
          );
          this.newSamples[knowledgeId] = "";
          setTimeout(() => this.refresh(), 2000);
          return true;
        }
      }
      this.newSamples[knowledgeId] = "";
      return false;
    });
  }

  toggleDefault(event, id) {
    // console.log(event);
    const source = event.source;
    const active = event.checked;
    source.toggle();
    const on = _("dialog.warning.confirm-default-on");
    const off = _("dialog.warning.confirm-default-off");
    const msg = active
      ? this.translateService.instant(on)
      : this.translateService.instant(off);
    const dialogRef = this.dialog.open(ConfirmationDialogComponent, {
      data: {
        messageHTML: `<div>${msg} <span class="highlight">${id}</span>? </div> `,
        destructive: false,
      },
    });

    dialogRef.afterClosed().subscribe((confirmed) => {
      const builtin = this.builtins.find((b) => b.id === id);
      if (builtin) {
        builtin.default = confirmed ? active : !active;
        if (confirmed) {
          source.toggle();
          this.builtinService.update(id, { default: builtin.default });
        }
      }
    });
  }
}
