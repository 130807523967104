import { Component, OnInit, NgZone, OnDestroy } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { Subscription } from 'rxjs';
import { Language, SupportedLanguages } from '@models/misc';
import { AuthService } from '../../services/auth.service';
import { SettingService } from '@shared';


@Component({
  selector: 'app-auth',
  templateUrl: './auth.component.html',
  styleUrls: ['./auth.component.scss']
})

export class AuthComponent implements OnInit, OnDestroy {
  authSub: Subscription;
  languages: Language[];
  language: Language;

  email: string;
  password: string;
  errorMessage: string;
  redirect: string;
  loading: boolean;

  constructor(
    private authService: AuthService,
    private router: Router,
    private route: ActivatedRoute,
    private ngZone: NgZone,
    private settingService: SettingService
  ) {
  }

  ngOnInit() {
    this.initialize();
    this.language = this.settingService.settings.language;
    this.languages = [...[this.language], ...SupportedLanguages.filter(l => l !== this.language)];
    this.route.queryParams
      .subscribe(params => this.redirect = params.redirect || '/');

    this.authSub = this.authService.authUserChanges.subscribe(
      authUser => {
        if (authUser) {
          console.log('Already signed in. Redirecting to', this.redirect);
          this.ngZone.run(() => this.router.navigate([this.redirect]));
        }
      }
    );
  }

  ngOnDestroy() {
    this.initialize();
    if (this.authSub) this.authSub.unsubscribe();
  }

  initialize() {
    this.email = '';
    this.password = '';
    this.errorMessage = '';
    this.redirect = '';
    this.loading = false;
  }

  setLanguage(language: Language) {
    this.language = this.settingService.setLanguage(language);
  }

  async signIn() {
    this.loading = true;
    const success = await this.authService.signIn(this.email, this.password);
    if (!success) {
      this.password = '';
      this.loading = false;
    }
  }

}
