import { Injectable } from "@angular/core";
import * as firebase from "firebase/app";
import "firebase/messaging";
import { to } from "@utility";
import { TranslateService } from "@ngx-translate/core";
import DeviceDetector from "device-detector-js";

@Injectable({
  providedIn: "root",
})
export class FcmService {
  fcm: firebase.messaging.Messaging;
  token: string;
  active = true;
  intervals: any[] = [];
  title: string;
  hidden: string;
  visibilityChange: string;
  mobile: boolean;
  fcmKey: string;

  constructor(private translateService: TranslateService) {}

  initialize(fcmKey: string, mobile: boolean = false) {
    this.mobile = !!mobile;
    this.fcmKey = fcmKey;

    if (!this.mobile) {
      this.title = document.title;
      if (typeof document.hidden !== "undefined") {
        this.hidden = "hidden";
        this.visibilityChange = "visibilitychange";
      } else if (typeof (document as any).msHidden !== "undefined") {
        this.hidden = "msHidden";
        this.visibilityChange = "msvisibilitychange";
      } else if (typeof (document as any).webkitHidden !== "undefined") {
        this.hidden = "webkitHidden";
        this.visibilityChange = "webkitvisibilitychange";
      }

      if (
        typeof document.addEventListener === "undefined" ||
        this.hidden === undefined
      ) {
        console.log("This browser doesn't support the Page Visibility API.");
      } else {
        document.addEventListener(
          this.visibilityChange,
          () => {
            if (document[this.hidden]) {
              this.active = false;
            } else {
              this.active = true;
              this.intervals.forEach((id) => clearInterval(id));
              this.intervals = [];
              document.title = this.title;
            }
          },
          false
        );
      }

      if (firebase.messaging.isSupported()) {
        this.fcm = firebase.messaging();
        this.fcm.usePublicVapidKey(this.fcmKey);

        this.fcm.onTokenRefresh(async () => {
          const [tokenErr, token] = await to(this.fcm.getToken());
          // if (tokenErr) console.error(tokenErr);
          if (token) this.token = token;
        });

        this.fcm.onMessage((payload) => {
          console.log(payload);
          if (!this.active && payload.notification)
            this.alertPageTitle(payload.notification.title);
          // if (payload.notification) this.pushNotification(payload.notification.title, payload.notification.body);
        });
      }
    }
  }

  async mobileGetToken(): Promise<string> {
    return new Promise((resolve, reject) => {
      (window as any).FirebasePlugin.getToken(
        (deviceToken) => resolve(deviceToken),
        (err) => reject(err)
      );
    });
  }

  async mobileRequestPermission() {
    return new Promise((resolve, reject) => {
      (window as any).FirebasePlugin.hasPermission((hasPermission) => {
        hasPermission
          ? resolve(true)
          : (window as any).FirebasePlugin.grantPermission((granted) =>
              resolve(granted)
            );
      });
    });
  }

  async getNotificationToken(): Promise<{ name: string; token: string }> {
    // console.log('Not Mobile', !this.mobile);
    try {
      if (this.mobile) {
        const [permissionErr, granted] = await to(
          this.mobileRequestPermission()
        );
        if (permissionErr) throw permissionErr;
        if (granted) {
          if (!this.token) {
            const [tokenErr, deviceToken] = await to(this.mobileGetToken());
            if (tokenErr) throw tokenErr;
            if (!deviceToken) return null;
            this.token = deviceToken;
          }
        }
      } else {
        if (!firebase.messaging.isSupported()) return null;
        if (Notification.permission !== "granted") {
          await Notification.requestPermission();
        }

        if (Notification.permission === "granted") {
          if (!this.token) {
            const [tokenErr, deviceToken] = await to(this.fcm.getToken());
            if (tokenErr) throw tokenErr;
            if (!deviceToken) return null;
            this.token = deviceToken;
          }
        }
      }

      const deviceDetector = new DeviceDetector();
      const device = deviceDetector.parse(navigator.userAgent);
      const name =
        device.device.brand && device.device.model
          ? `${device.device.brand} ${device.device.model}`
          : `${device.os.name} ${device.os.version} ${device.client.name}`;
      // console.log('getNotificationToken', this.token);
      return { name: name || "", token: this.token };
    } catch (e) {
      console.error(e);
    }
    return null;
  }

  alertPageTitle(m: string) {
    if (this.intervals.length > 0) return false;
    const message = this.translateService.instant(m);
    document.title = message;
    const id = setInterval(() => {
      document.title = document.title === this.title ? message : this.title;
    }, 1500);
    this.intervals.push(id);
  }
}
