<img
  [class.hidden]="!darkmode"
  class="loader"
  src="assets/img/loader-dark.gif"
  [style.width]="size ? size + 'rem' : '5rem'"
/>
<img
  [class.hidden]="darkmode"
  class="loader"
  src="assets/img/loader.gif"
  [style.width]="size ? size + 'rem' : '5rem'"
/>
