import { Timezone } from "../misc";

export * from "./logger";
export * from "./language";
const shorthash = require("shorthash");
const moment = require("moment-timezone");

export const to = <T>(promise: Promise<T>): Promise<[any, T?]> => {
	return new Promise(resolve => {
		promise
			.then(data => {
				resolve([null, data]);
				return;
			})
			.catch(err => {
				resolve([err]);
				return;
			});
	});
};

export const toAll = async <T>(
	promiseArr: Promise<T>[]
): Promise<[T[], T[]?]> => {
	const [err, res] = await to(Promise.all(promiseArr));
	if (err) return [err];
	const failed = res.filter(r => !r);
	if (failed.length === 0) return [null, res];
	const done = res.filter(r => r);
	return [failed, done];
};

export const emptyArr = (arr: any[]): boolean => {
	if (!arr) return true;
	if (arr.length === 0) return true;
	return false;
};

export const removeEmpty = (arr: any[]) => {
	return arr.filter(a => a !== "" && a !== null && a !== undefined);
};

export const unique = (arr: any[]): any[] => {
	arr = removeEmpty(arr);
	if (arr.length > 0 && typeof arr[0] === "object") {
		try {
			const tmpArr = arr.map(a => JSON.stringify(a).replace(" ", "").trim());
			const newTmpArr = [...new Set(tmpArr)];
			return newTmpArr.map(a => JSON.parse(a));
		} catch (e) {
			return [...new Set(arr)];
		}
	} else {
		return [...new Set(arr)];
	}
};

export const duplicates = (arr: any[]) => {
	const sorted = arr.slice().sort();
	let result: any = [];
	for (let i = 0; i < sorted.length - 1; i++) {
		if (sorted[i + 1] === sorted[i]) {
			result.push(sorted[i]);
		}
	}
	return unique(result);
};

export const contain = (arr: any[], element: any): boolean => {
	return arr.includes(element);
};

export const round = (number: number): number => {
	if (number) {
		return Math.floor(number * 10000) / 10000;
	}
	return 0;
};

export const flatten = (arr: any[]) => {
	return [].concat.apply([], arr);
};

export const wait = (second: number) => {
	return new Promise(resolve => {
		// console.log("waiting...");
		setTimeout(_ => {
			// log.debug("waited", second, "s");
			resolve();
		}, second * 1000);
	});
};

export const removeElementByIndex = (arr: any[], index: number) => {
	if (index === -1) return arr;
	return [...arr.slice(0, index), ...arr.slice(index + 1)];
};

export const clone = (obj: any) => {
	try {
		return JSON.parse(JSON.stringify(obj));
	} catch (e) {
		return {};
	}
};

export const stringToDate = (str: string): Date => {
	const timezone = parseInt(str.slice(-5, -2));
	const arr = str.split(/[^0-9]/).map(n => parseInt(n) || 0);
	const d =
		Date.UTC(arr[0], arr[1] - 1, arr[2], arr[3], arr[4], arr[5]).valueOf() -
		timezone * 3600000;
	return new Date(d);
};

export const formatDate = (d: Date | number): string => {
	const timezone =
		Intl.DateTimeFormat().resolvedOptions().timeZone || Timezone.HK;
	const today = moment().tz(timezone);
	const date = moment.tz(d, timezone);
	// console.log(today, date);
	return today.year() === date.year() && today.dayOfYear() === date.dayOfYear()
		? date.format("HH:mm")
		: today.year() === date.year()
		? date.format("MM/DD HH:mm")
		: date.format("YYYY/MM/DD HH:mm");
};

export const formatTime = (d: Date | number): string => {
	const timezone =
		Intl.DateTimeFormat().resolvedOptions().timeZone || Timezone.HK;
	const date = moment.tz(d, timezone);
	return date.format("HH:mm");
};

export const capitalize = (text: string): string => {
	return text[0].toUpperCase() + text.substring(1);
};

export const compareArrayContent = (arr1: any[], arr2: any[]) => {
	if (arr1.length !== arr2.length) return false;
	for (let i = 0; i < arr1.length; i++) {
		if (arr1[i] !== arr2[i]) return false;
	}
	return true;
};

export const duplicateArray = (arr: any[]) => {
	let duplicate = null;
	for (let i = 0; i < arr.length - 1; i++) {
		for (let n = i + 1; n < arr.length; n++) {
			if (compareArrayContent(arr[i], arr[n])) {
				duplicate = arr[i];
				break;
			}
		}
	}
	return duplicate;
};

export const keys = (obj: any) => {
	if (!obj) return [];
	try {
		return Object.keys(obj).sort();
	} catch (e) {
		return [];
	}
};

export const mask = (text: string): string => {
	return shorthash.unique(text);
};

export const reverse = (text: string): string => {
	return text.split("").reverse().join("");
};

export const equal = (a: any, b: any): boolean => {
	try {
		if (!a || !b) return false;
		return JSON.stringify(a) === JSON.stringify(b);
	} catch (e) {
		return false;
	}
};
