import { Injectable } from "@angular/core";
import * as firebase from "firebase/app";
import "firebase/storage";
import "firebase/firestore";
import { to, mask, unique } from "@utility";
import { NotificationService } from "./notification.service";
import { getExtension } from "mime";
import { AttachmentType, Attachment } from "@models/messenger";
import uuid from "uuid/v1";
import { Client } from "@models/user";
const FILE_LIMIT = 25000000;

@Injectable({
	providedIn: "root",
})
export class FirestoreService {
	private _storage: firebase.storage.Storage;
	private _storageRoot: firebase.storage.Reference;
	private _db: firebase.firestore.Firestore;

	devHost = "https://storage.googleapis.com/magicbot-238102.appspot.com/";
	prodHost = "https://storage.getfriday.ai/";

	constructor(private notificationService: NotificationService) {
		this._storage = firebase.storage();
		this._storageRoot = this._storage.ref();
		this._db = firebase.firestore();
	}

	async toBase64(file: File): Promise<string> {
		return new Promise(resolve => {
			const reader = new FileReader();
			reader.readAsDataURL(file);
			reader.onload = () =>
				resolve(reader.result.toString().replace(/^data:(.*,)?/, ""));
			reader.onerror = error => resolve(null);
		});
	}

	cleanPath(path: string) {
		if (!path) return "";
		path = path[0] === "/" ? path : "/" + path;
		const i = path.length - 1;
		path = path[i] === "/" ? path.substring(0, i) : path;
		return path;
	}

	urlToLocation(url: string): string {
		if (url.includes(this.devHost)) return url.replace(this.devHost, "");
		if (url.includes(this.prodHost)) return url.replace(this.prodHost, "");
		return null;
	}

	checkClientQuota(
		client: Client | string,
		size: number,
		free: boolean
	): boolean {
		if (free || typeof client === "string") return true;
		if (!client.quotas || !client.quotas.storage) return true;
		return client.quotas.storage > size;
	}

	async updateQuota(client: Client | string, size: number, free: boolean) {
		if (free || typeof client === "string") return true;
		if (!client.quotas || !client.quotas.storage) return true;
		const clientId = client.id;
		const [updateErr, updateRes] = await to(
			this._db
				.collection("clients")
				.doc(clientId)
				.update({
					"quotas.storage": client.quotas.storage - size,
				})
		);
		if (updateErr) console.log(updateErr);
		return true;
	}

	async upload(
		userId: string,
		file: File,
		production: boolean,
		path?: string,
		rename?: string,
		version = false
	): Promise<Attachment> {
		if (!file) return null;
		if (file.size > FILE_LIMIT)
			return this.notificationService.error("error.firestore.size-limit", null);

		const type = file.type.includes("image")
			? AttachmentType.image
			: file.type.includes("video")
			? AttachmentType.video
			: file.type.includes("audio")
			? AttachmentType.audio
			: AttachmentType.file;

		const ext = getExtension(file.type);
		let filename = rename || file.name.split(".")[0];
		filename = filename.replace(/\s/g, "_");
		const name = `${filename}.${ext}`;
		path = this.cleanPath(path);

		const location = `${mask(userId)}${path}/${filename}${
			version ? "_" + Math.ceil(new Date().valueOf() / 1000) : ""
		}.${ext}`;

		return new Promise(resolve => {
			const ref = this._storageRoot.child(location);
			const uploadTask = ref.put(file);
			uploadTask.on(
				"state_changed",
				snapshot => {},
				error => {
					console.log(error);
					this.notificationService.error("error.firestore.upload");
					resolve(null);
				},
				async () => {
					const url = encodeURI(
						`${production ? this.prodHost : this.devHost}${location}`
					);
					let attachment: Attachment = {
						id: uuid(),
						name,
						url,
						type,
						location,
						size: file.size,
					};
					console.log("Upload firestore done", attachment);
					resolve(attachment);
				}
			);
		});
	}

	async clientUpload(
		client: Client | string,
		file: File,
		production: boolean,
		path?: string,
		rename?: string,
		free = false,
		version = false
	): Promise<Attachment> {
		if (!file) return null;
		if (!this.checkClientQuota(client, file.size, free))
			return this.notificationService.error(
				"error.firestore.storage-full",
				null
			);
		const clientId = typeof client === "string" ? client : client.id;
		const attachment = await this.upload(
			clientId,
			file,
			production,
			path,
			rename,
			version
		);
		if (attachment) await this.updateQuota(client, file.size, free);
		return attachment;
	}

	async clientBatchUpload(
		client: Client | string,
		files: File[],
		production: boolean,
		path?: string,
		free = false,
		version = false
	): Promise<Attachment[]> {
		if (!files || files.length === 0) return null;
		let size = 0;
		let promiseArr: Promise<Attachment>[] = [];
		for (let file of files) {
			if (file.size > FILE_LIMIT) {
				this.notificationService.error("error.firestore.size-limit");
				continue;
			}
			promiseArr.push(
				this.clientUpload(client, file, production, path, null, free, version)
			);
			size += file.size;
		}

		if (typeof client !== "string") {
			if (!free && client.quotas && client.quotas.storage < size)
				return this.notificationService.error(
					"error.firestore.storage-full",
					null
				);
		}

		const [uploadErr, attachments] = await to(Promise.all(promiseArr));
		if (uploadErr || !attachments) return null;
		await this.updateQuota(client, size, free);
		return unique(attachments);
	}

	async delete(location: string): Promise<boolean> {
		if (!location) return false;
		const ref = this._storageRoot.child(location);
		const [deleteErr, deleteRes] = await to(ref.delete());
		if (deleteErr) {
			if (deleteErr.code === "storage/object-not-found") {
				return true;
			} else {
				console.log(deleteErr);
				return this.notificationService.error("error.firestore.delete", false);
			}
		}
		return true;
	}

	async clientDelete(
		client: Client | string,
		attachment: Attachment,
		free = false
	): Promise<boolean> {
		if (!attachment) return true;
		const deleted = await this.delete(attachment.location);
		if (deleted) await this.updateQuota(client, -attachment.size, free);
		return true;
	}

	async clientBatchDelete(
		client: Client | string,
		attachments: Attachment[],
		free = false
	): Promise<boolean> {
		if (!attachments || attachments.length === 0) return true;
		await Promise.all(attachments.map(a => this.clientDelete(client, a, true)));
		await this.updateQuota(
			client,
			attachments.reduce((acc, cur) => (acc -= cur.size || 0), 0),
			free
		);
		return true;
	}
}
