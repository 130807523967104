import { Injectable, NgZone } from '@angular/core';
import { ActivatedRouteSnapshot, RouterStateSnapshot, CanActivate, Router } from '@angular/router';
import { Observable } from 'rxjs';
import { AuthService } from '../services/auth.service';


@Injectable({
  providedIn: 'root'
})

export class AuthGuard implements CanActivate {
  constructor(
    private authService: AuthService,
    private router: Router,
    private ngZone: NgZone
  ) { }

  canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<boolean> {
    // console.log('[AuthGuard] Check Activatable');
    return new Observable(observer => {
      this.authService.authUserChanges.subscribe(
        authUser => {
          if (this.authService.initialized) {
            // console.log('[AuthGuard] User:', authUser);
            if (authUser) {
              // console.log('[AuthGuard] Authenticated');
              observer.next(true);
            } else {
              console.log('[AuthGuard] Not authenticated. Redirecting to /auth');
              observer.next(false);
              this.ngZone.run(() => {
                if (state.url !== '/' && state.url !== '' && state.url !== '/welcome') {
                  // window.location.href = 'auth?redirect=' + state.url;
                  this.router.navigate(['/auth'], {
                    queryParams: {
                      redirect: state.url
                    }
                  });
                } else {
                  // window.location.href = 'auth';
                  this.router.navigate(['/auth']);
                }
              });
            }
          }
        });
    });
  }
}
