import { Component, OnInit } from '@angular/core';
import { Admin } from '@models/user';
import { Subscription } from 'rxjs';
import { AuthService } from '../../services/auth.service';

@Component({
  selector: 'app-dashboard',
  templateUrl: './dashboard.component.html',
  styleUrls: ['./dashboard.component.scss']
})
export class DashboardComponent implements OnInit {
  admin: Admin;
  adminSub: Subscription;

  constructor(
    private authService: AuthService,
  ) { }

  ngOnInit() {
    this.adminSub = this.authService.adminChanges.subscribe(async admin => {
      this.admin = admin;
    });
  }

}
