import { Component, OnInit, Input } from "@angular/core";
import { Language, SupportedLanguages } from "@models/misc";
import { Router } from "@angular/router";
import { DomSanitizer } from "@angular/platform-browser";
import { User } from "@models/user";
import { SettingService } from "../../services/setting.service";

@Component({
  selector: "app-sidebar",
  templateUrl: "./sidebar.component.html",
  styleUrls: ["./sidebar.component.scss"],
})
export class SidebarComponent implements OnInit {
  @Input() user: User;
  @Input() routes: any[];
  @Input() authService: any;
  languages: Language[];
  language: Language;
  compact: boolean;

  constructor(
    private router: Router,
    private sanitization: DomSanitizer,
    private settingService: SettingService
  ) {}

  ngOnInit() {
    this.language = this.settingService.settings.language;
    this.languages = [
      ...[this.language],
      ...SupportedLanguages.filter((l) => l !== this.language),
    ];
    this.compact = this.settingService.settings.compact;
  }

  setLanguage(language: Language) {
    this.language = this.settingService.setLanguage(language);
    this.languages = [
      ...[this.language],
      ...SupportedLanguages.filter((l) => l !== this.language),
    ];
  }

  toggleSidebar() {
    this.compact = this.settingService.toggleSidebar();
  }

  isActiveRoute(url: string) {
    return this.router.url.indexOf(url) !== -1;
  }

  userPhoto() {
    return this.sanitization.bypassSecurityTrustStyle(
      `url(${this.user.info.photoURL})`
    );
  }

  signOut() {
    return this.authService.signOut();
  }
}
