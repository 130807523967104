import { Component, OnInit, Input } from '@angular/core';
import { Language, SupportedLanguages } from '@models/misc';
import { SettingService } from '../../../services/setting.service';

@Component({
  selector: 'app-nav-top',
  templateUrl: './top.component.html',
  styleUrls: ['./top.component.scss']
})
export class TopComponent implements OnInit {
  @Input() authService: any;
  languages: Language[];
  language: Language;

  constructor(
    private settingService: SettingService,
  ) { }

  ngOnInit() {
    this.language = this.settingService.settings.language;
    this.languages = [...[this.language], ...SupportedLanguages.filter(l => l !== this.language)];
  }

  setLanguage(language: Language) {
    this.language = this.settingService.setLanguage(language);
  }

  signOut() {
    return this.authService.signOut();
  }

}
