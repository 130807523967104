import { Component, OnInit, OnDestroy } from "@angular/core";
import { ApiService } from "../../../services/api.service";
import { TaskService } from "../../../services/task.service";
import { Task } from "@models/task";
import { Subscription } from "rxjs";

@Component({
	selector: "app-builtin-task",
	templateUrl: "./builtin-task.component.html",
	styleUrls: ["./builtin-task.component.scss"],
})
export class BuiltinTaskComponent implements OnInit, OnDestroy {
	tasks: Task[];
	sub: Subscription;

	constructor(private taskService: TaskService) {}

	ngOnInit() {
		this.sub = this.taskService.builtinTaskChanges.subscribe(
			tasks => (this.tasks = tasks)
		);
	}

	ngOnDestroy() {
		if (this.sub) this.sub.unsubscribe();
	}

	async submitTask(taskId: string, event: any) {
		const answers: string[] = event.answers;
		const replyOnly: boolean = event.replyOnly;
		return this.taskService.submitTask(taskId, answers, replyOnly);
	}
}
