import { Injectable } from '@angular/core';
import * as firebase from 'firebase/app';
import 'firebase/auth';
import 'firebase/firestore';
import { to } from '@utility';
import { Admin } from '@models/user';
import { AuthService } from './auth.service';
import { NotificationService } from '@shared';
import { environment } from '../../environments/environment';

@Injectable({
  providedIn: 'root'
})
export class AdminService {
  private _db: firebase.firestore.Firestore;
  private _collection: firebase.firestore.CollectionReference;
  public admin: Admin;

  constructor(
    private authService: AuthService,
    private notificationService: NotificationService,
  ) {
    // console.log('[ClientService] Constructor');
    this._db = firebase.firestore();
    this._collection = this._db.collection(environment.ADMIN_COLLECTION);
    this.authService.adminChanges.subscribe(admin => {
      this.admin = admin;
    });
  }

  async updateAdmin(data: any): Promise<boolean> {
    const update = { ...data, ...{ _updatedAt: new Date().valueOf() } };
    const [updateErr, updateRes] = await to(this._collection.doc(this.admin.id).update(update));
    if (updateErr) return this.notificationService.error('error.data.update', false);
    return true;
  }

}
