import { Component, OnInit, Input, Output, EventEmitter } from "@angular/core";
import { Task, TaskOption, UNKNOWN_ANSWER } from "@models/task";
import { MatDialog } from "@angular/material/dialog";
import { ConfirmationDialogComponent } from "../dialogs/confirmation/confirmation.component";
import { TranslateService } from "@ngx-translate/core";

@Component({
	selector: "app-task-card",
	templateUrl: "./task-card.component.html",
	styleUrls: ["./task-card.component.scss"],
})
export class TaskCardComponent implements OnInit {
	@Input() task: Task;
	@Input() admin = false;
	@Input() readonly = false;
	@Output() submit = new EventEmitter();

	submitting = false;
	answers: string[] = [];
	options: TaskOption[] = [];
	hiddenOptions: TaskOption[] = [];
	moreOptions = false;
	showMore = false;
	displayThreshold = 0.35;
	UNKNOWN_ANSWER = UNKNOWN_ANSWER;

	constructor(
		public dialog: MatDialog,
		private translateService: TranslateService
	) {}

	ngOnInit() {
		this.options = this.task.options.filter(
			o => o.confidence >= this.displayThreshold
		);
		this.hiddenOptions = this.task.options.filter(
			o => o.confidence < this.displayThreshold
		);
		// console.log(this.options, this.hiddenOptions);
		if (this.options.length === 0) {
			this.options = this.task.options;
			this.hiddenOptions = [];
		}
		this.moreOptions = this.hiddenOptions.length > 0;
	}

	toggleAnswer(knowledgeId: string) {
		if (knowledgeId == UNKNOWN_ANSWER) {
			this.answers = this.answers.includes(UNKNOWN_ANSWER)
				? []
				: [UNKNOWN_ANSWER];
		} else {
			const none = this.answers.indexOf(UNKNOWN_ANSWER);
			if (none !== -1) this.answers.splice(none);
			const index = this.answers.indexOf(knowledgeId);
			if (index == -1) {
				this.answers.push(knowledgeId);
			} else {
				this.answers.splice(index, 1);
			}
		}
	}

	isChecked(knowledgeId: string) {
		return this.answers.includes(knowledgeId);
	}

	async submitAnswers(replyOnly = false) {
		if (this.answers.length > 0) {
			const k = this.task.options.find(o => o.knowledgeId === this.answers[0]);
			if (
				!replyOnly &&
				!this.answers.includes(UNKNOWN_ANSWER) &&
				!this.task.builtin &&
				k
			) {
				const dialogRef = this.dialog.open(ConfirmationDialogComponent, {
					data: {
						messageHTML: `<div>${this.translateService.instant(
							"task.confirm-learn.1"
						)}<span class="highlight">${
							this.task.query
						}</span>${this.translateService.instant(
							"task.confirm-learn.2"
						)}<span class="highlight">${
							k.knowledgeQuery
						}</span>${this.translateService.instant(
							"task.confirm-learn.3"
						)}<br>${this.translateService.instant(
							"task.confirm-learn.4"
						)}</div> `,
					},
				});

				dialogRef.afterClosed().subscribe(async confirmed => {
					if (confirmed) this.submit.emit({ answers: this.answers, replyOnly });
					this.submitting = true;
				});
			} else {
				this.submit.emit({ answers: this.answers, replyOnly });
				this.submitting = true;
			}
		}
	}
}
