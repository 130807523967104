<div class="page__toolbar">
  <button class="text-button" (click)="triggerLoadJSON()">
    <i class="material-icons">cloud_upload</i>
    <span translate>general.import</span>
  </button>
  <input id="load-json" class="hidden" type="file" (change)="loadJSON($event)" accept="application/json">

  <button class="text-button" (click)="export()">
    <i class="material-icons">cloud_download</i>
    <span translate>general.export</span>
  </button>
  <a id="export" class="hidden"></a>
</div>

<div class="page__content">
  <div class="setting" *ngIf="initialized">
    <mat-accordion multi="true" displayMode="flat">
      <mat-expansion-panel>
        <mat-expansion-panel-header>
          <mat-panel-title>{{'config.word' | translate}}</mat-panel-title>
        </mat-expansion-panel-header>

        <div class="form">
          <div class="form__row">
            <div class="form__label" translate>general.add</div>
            <div class="form__field">
              <input type="text" [(ngModel)]="newVerbalWord" (keyup.enter)="addVerbalWord()">
            </div>
            <div class="form__action" *ngIf="newVerbalWord!==''">
              <button class="icon-button warn" (click)="newVerbalWord='';">
                <i class="material-icons">cancel</i>
              </button>
              <button class="icon-button success" (click)="addVerbalWord()">
                <i class="material-icons">check_circle</i>
              </button>
            </div>
          </div>

          <div class="samples">
            <div class="samples__item" *ngFor="let word of verbal.word; let i = index;">
              {{word}}
              <a class="error" (click)="deleteVerbalWord(word)">
                <i class="material-icons">close</i>
              </a>
            </div>
          </div>
        </div>
      </mat-expansion-panel>

      <mat-expansion-panel>
        <mat-expansion-panel-header>
          <mat-panel-title>{{'config.translation' | translate}}</mat-panel-title>
        </mat-expansion-panel-header>

        <div class="form">
          <div class="form__row">
            <div class="form__label" translate>general.add</div>
            <div class="form__field">
              <input type="text" [(ngModel)]="newOrigin">
              <i class="material-icons">chevron_right</i>
              <input type="text" [(ngModel)]="newTranslation" (keyup.enter)="addVerbalTranslation()">
            </div>

            <div class="form__action" *ngIf="newOrigin!=='' && newTranslation!==''">
              <button class="icon-button warn" (click)="newOrigin='';newTranslation='';">
                <i class="material-icons">cancel</i>
              </button>
              <button class="icon-button success" (click)="addVerbalTranslation()">
                <i class="material-icons ">check_circle</i>
              </button>
            </div>
          </div>

          <div class="samples">
            <div class="samples__item" *ngFor="let origin of translationKeys">
              {{origin}} : {{verbal.translation[origin]}}
              <a class="error" (click)="deleteVerbalTranslation(origin)">
                <i class="material-icons">close</i>
              </a>
            </div>
          </div>
        </div>

      </mat-expansion-panel>
    </mat-accordion>
  </div>
  <app-loader *ngIf="!initialized"></app-loader>
</div>
