<mat-drawer-container *ngIf="admin && initialized" autosize>
  <mat-drawer id="app-sidebar" mode="side" opened disableClose>
    <app-sidebar [user]="admin" [authService]="authService" [routes]="routes"></app-sidebar>
  </mat-drawer>

  <mat-drawer-content>
    <app-nav-top id="app-nav-top" [authService]="authService"></app-nav-top>
    <router-outlet></router-outlet>
    <app-nav-bottom id="app-nav-bottom" [routes]="routes"></app-nav-bottom>
  </mat-drawer-content>

</mat-drawer-container>