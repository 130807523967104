<div class="page__content">
  <ng-container *ngIf="tasks">
    <app-empty
      *ngIf="tasks.length == 0"
      [icon]="'inbox'"
      [message]="'task.no-task'"
    ></app-empty>

    <div class="tasks" *ngIf="tasks.length > 0">
      <app-task-card
        *ngFor="let task of tasks"
        [task]="task"
        [admin]="true"
        [readonly]="!task.public"
        (submit)="submitTask(task.taskId, $event)"
      ></app-task-card>
      <div class="end-indicator" translate>general.end</div>
    </div>
  </ng-container>

  <app-loader *ngIf="!tasks"></app-loader>
</div>
