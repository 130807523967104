import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';
import { Admin, AdminParams } from '@models/user';
import * as firebase from 'firebase/app';
import 'firebase/auth';
import 'firebase/firestore';
import { to } from '@utility';
import { NotificationService } from '@shared';
import { environment } from '../../environments/environment';

@Injectable({
  providedIn: 'root'
})

export class AuthService {
  private _authUser$: BehaviorSubject<firebase.User> = new BehaviorSubject(null);
  public authUserChanges = this._authUser$.asObservable();
  public authUser: firebase.User;

  private _admin$: BehaviorSubject<Admin> = new BehaviorSubject(null);
  public adminChanges = this._admin$.asObservable();

  public authProviders: string[] = [];

  public initialized = false;
  adminUnsub: any;

  constructor(
    private notificationService: NotificationService,
  ) {
    firebase.auth().onAuthStateChanged(
      async authUser => {
        console.log('[AuthService] Auth Changed');
        this.initialized = true;
        if (!authUser) return this.reset();
        console.log('[AuthService] Authenticated:', authUser.email);
        const admin = await this.getAdminProfile(authUser);
        if (!admin) return this.reset();
        this.authUser = authUser;
        this._authUser$.next(authUser);
      }, error => {
        return this.reset();
      });
  }

  reset() {
    if (this.adminUnsub) this.adminUnsub();
    this._admin$.next(null);
    this.authUser = null;
    this._authUser$.next(null);
  }

  async refreshUser() {
    if (firebase.auth().currentUser) {
      console.log('[AuthService] Refresh user');
      await firebase.auth().currentUser.reload();
      this.authUser = firebase.auth().currentUser;
      this._authUser$.next(this.authUser);
    }
  }

  async signIn(email: string, password: string): Promise<boolean> {
    if (!email || !password) return this.notificationService.error('error.auth.credentials-required', false);
    const [signInErr, signInRes] = await to(firebase.auth().signInWithEmailAndPassword(email, password));
    if (signInErr) return this.notificationService.error(`error.firebase.${signInErr.code}`, false);
    console.log('[AuthService] Signed in', signInRes.user.email);
    return true;
  }

  async signOut() {
    console.log('[AuthService] signOut');
    await to(firebase.auth().signOut());
    environment.mobile ? window.location.href = '#/auth' : window.location.href = 'auth';
  }

  async getAdminProfile(authUser: firebase.User): Promise<Admin> {
    if (!authUser.uid) return null;

    const [permissionErr, auth] = await to(firebase.auth().currentUser.getIdTokenResult());
    if (permissionErr) return this.notificationService.error('error.auth.check-permission', null);

    if (!auth.claims.admin) {
      this.notificationService.error('error.auth.admin-only');
      setTimeout(_ => this.signOut(), 2000);
      return null;
    }

    const collection = firebase.firestore().collection(environment.ADMIN_COLLECTION);
    const [docErr, doc] = await to(collection.doc(authUser.uid).get());
    if (docErr) return this.notificationService.error('error.unknown', null);
    if (!doc.exists) {
      console.log('[AuthService] Create new admin profile:', authUser.uid);
      let profileObj: AdminParams = {
        id: authUser.uid,
        info: {
          email: authUser.email,
          displayName: authUser.displayName,
          phoneNumber: authUser.phoneNumber,
          photoURL: authUser.photoURL
        }
      };
      const adminProfile = new Admin(profileObj);
      const [createErr, createRes] = await to(collection.doc(authUser.uid).set(adminProfile.toObject()));
      if (createErr) return this.notificationService.error('error.user.create', null);
    } else {
      const now = new Date().valueOf();
      const admin = new Admin(doc.data() as AdminParams);

      if (now - admin._updatedAt > 86400000) {
        const [resetErr, resetRes] = await to(collection.doc(authUser.uid).set(admin.toObject()));
        if (resetErr) console.error(resetErr);
      }
    }

    return new Promise(resolve => {
      this.adminUnsub = collection.doc(authUser.uid).onSnapshot(
        doc => {
          if (doc.exists) {
            const admin = new Admin(doc.data() as AdminParams);
            // console.log('[AuthService] admin data changed', admin);
            this._admin$.next(admin);
            resolve(admin);
          } else {
            console.log('[AuthService] admin data removed');
            this.notificationService.error('error.user.removed');
            resolve(null);
          }
        },
        error => {
          console.error(error);
          this.notificationService.error('error.unknown');
          this.signOut();
          resolve(null);
        });
    });
  }

  async changePassword(password: string, confirmPassword: string) {
    if (password !== confirmPassword) return this.notificationService.error('error.auth.password-mismatch', false);
    const [updateErr, updateRes] = await to(firebase.auth().currentUser.updatePassword(password));
    if (updateErr) return this.notificationService.error(`error.firebase.${updateErr.code}`, false);
    return true;
  }
}
