<div class="page__toolbar">
  <div class="left row">
    <button class="text-button" (click)="addBuiltin()">
      <i class="material-icons">add</i>
      <span translate>general.add</span>
    </button>

    <button class="text-button" (click)="triggerLoadJSON()">
      <i class="material-icons">cloud_upload</i>
      <span translate>general.import</span>
    </button>
    <input
      id="load-json"
      class="hidden"
      type="file"
      (change)="loadJSON($event)"
      accept="application/json"
    />

    <button class="text-button" (click)="export()">
      <i class="material-icons">cloud_download</i>
      <span translate>general.export</span>
    </button>
    <a id="export" class="hidden"></a>

    <button class="text-button" (click)="saveAll()" [disabled]="!edited">
      <i class="material-icons">save</i>
      <span translate>general.save</span>
    </button>
  </div>

  <div class="right row">
    <div
      class="status row"
      [ngClass]="{ success: trained, warn: !trained }"
      *ngIf="schedule"
    >
      <span class="status__signal"></span>
      <label>{{ schedule }}</label>
    </div>

    <button class="icon-button white" (click)="refresh()">
      <i class="material-icons">autorenew</i>
    </button>
  </div>
</div>

<div class="page__content">
  <div class="setting" *ngIf="initialized && !loading">
    <mat-accordion multi="true" displayMode="flat" *ngIf="builtins.length > 0">
      <mat-expansion-panel
        *ngFor="let builtin of builtins; let i = index"
        [expanded]="false"
      >
        <!-- [expanded]="attention(id)" -->
        <mat-expansion-panel-header>
          <mat-panel-title>{{ builtin.id }}</mat-panel-title>
        </mat-expansion-panel-header>

        <div class="form">
          <div class="form__row">
            <div class="form__label" translate>general.default</div>
            <div class="form__field">
              <mat-slide-toggle
                [checked]="builtin.default"
                (change)="toggleDefault($event, builtin.id)"
              >
              </mat-slide-toggle>
            </div>
            <div class="form__action" *ngIf="!builtin.default">
              <button
                class="icon-button"
                (click)="deleteBuiltin(builtin.id)"
                [disabled]="builtin.default"
              >
                <i class="material-icons error">delete</i>
              </button>
            </div>
          </div>

          <div class="form__row">
            <div class="form__label" translate>general.query</div>
            <input
              class="form__field"
              type="text"
              [(ngModel)]="builtin.query"
              (change)="edited = true"
            />
          </div>

          <!-- <div class="form__section">locales</div> -->
          <div class="form__row" *ngFor="let lang of languages">
            <div class="form__label">{{ "general." + lang | translate }}</div>
            <input
              class="form__field"
              type="text"
              [(ngModel)]="builtin.locales[lang]"
              (change)="edited = true"
            />
          </div>

          <!-- <div class="form__section">samples</div> -->
          <div class="form__row">
            <div class="form__label" translate>config.add-sample</div>
            <div class="form__field">
              <input
                type="text"
                [(ngModel)]="newSamples[builtin.id]"
                (change)="edited = true"
                (keyup.enter)="addSample(builtin.id)"
              />
            </div>
            <div class="form__action" *ngIf="newSamples[builtin.id] !== ''">
              <button
                class="icon-button warn"
                (click)="newSamples[builtin.id] = ''"
              >
                <i class="material-icons">cancel</i>
              </button>
              <button
                class="icon-button success"
                (click)="addSample(builtin.id)"
              >
                <i class="material-icons">check_circle</i>
              </button>
            </div>
          </div>

          <div class="samples" *ngIf="builtinSamples[builtin.id]">
            <div
              class="samples__item"
              *ngFor="let sample of builtinSamples[builtin.id].kb"
              [class.warn]="showWarning(builtin.id, sample)"
            >
              {{ sample }}
              <a
                (click)="retrainSample(builtin.id, sample)"
                *ngIf="!builtinSamples[builtin.id].wit.includes(sample)"
                ><i class="material-icons">replay</i></a
              >
              <a class="error" (click)="deleteSample(builtin.id, sample)">
                <i class="material-icons">close</i>
              </a>
            </div>
          </div>
        </div>
      </mat-expansion-panel>
    </mat-accordion>
  </div>
  <app-empty
    *ngIf="builtins && builtins.length === 0"
    [icon]="'inbox'"
    [message]="'config.no-builtin'"
  ></app-empty>
  <app-loader *ngIf="!initialized || loading"></app-loader>
</div>
